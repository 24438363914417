import React from 'react';
import {PortType} from '..';

const Monfalcone: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='adriatic-subregion'
        d='M53.79,17.93c-.64.25-1.17.7-.93,1.2a1,1,0,0,1,.11.36.74.74,0,0,1,0,.26c-.25,0-.51-.05-.77-.06a4.17,4.17,0,0,1-1.88-.34,1.77,1.77,0,0,0-.9-.27,1.82,1.82,0,0,1-.15-.27,33.28,33.28,0,0,1-1.47-3h0a.28.28,0,0,0,0-.13c0-.43.37-.24.62-.24s.31-.5.31-.5l.41-.38.26.14c.37.24,1.07-.18,1.31-.42S52.27,14,52.41,14s.18.75.42.89L53,15l.24.06c.19,0,.42.06.67.09A18.31,18.31,0,0,0,53.79,17.93Z'
      />
      <circle className='port-center' cx='53' cy='18.67' r='0.41' />
      <circle className='port-circle' cx='53' cy='18.67' r='0.68' />
      <circle className='port-circle' cx='53' cy='18.67' r='0.94' />
    </g>
  );
};

export default Monfalcone;
