import React from 'react';
import {PortType} from '..';

const Iskenderun: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='other-subregion'
        d='M103.76,39.15c-.11.43-.29,1-.43,1.46l-.14-.1c-.42-.33-.75.08-.83.5s0,1.28-1.25,1.46a.89.89,0,0,0,0-.72c-.34-.5.94-1.46.56-1.68s-1,.13-1.61.44c-.4.21-.83-.14-1.18-.33,0-.07,0-.14,0-.21a1.22,1.22,0,0,1,0-.76c.31-.54,1.45-2.27,3.17-1.91a2.66,2.66,0,0,1,1.73.86A1.25,1.25,0,0,1,103.76,39.15Z'
      />
      <circle className='port-center' cx='101.46' cy='39.55' r='0.41' />
      <circle className='port-circle' cx='101.46' cy='39.55' r='0.68' />
      <circle className='port-circle' cx='101.46' cy='39.55' r='0.94' />
    </g>
  );
};

export default Iskenderun;
