import React from 'react';
import PageWrapper from '@erkport/components/PageWrapper';
import {getMonths, getYears} from '@erkport/services/erkport';
import WorldMapPage from './page';

const WorldMap = () => {
  return (
    <PageWrapper yearsProvider={getYears} monthsProvider={getMonths}>
      {(props) => <WorldMapPage {...props} />}
    </PageWrapper>
  );
};

export default WorldMap;
