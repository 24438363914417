import {defaultUser} from '@erkport/constants/AppConst';
import {AxiosRequestConfig} from 'axios';
import mock from '../MockConfig';

mock.onPost('/mock/auth').reply((request: AxiosRequestConfig) => {
  return [200, {token: 'token'}];
});

mock.onGet('/mock/auth').reply((request: AxiosRequestConfig) => {
  return [200, defaultUser];
});
