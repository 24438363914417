import React from 'react';
import {PortType} from '..';

const Valencia: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='baleric-subregion'
        d='M26.59,35.37C26.5,35.75,25.1,36.49,25,37s.06,1.37-.78,1.5a2,2,0,0,0-1.65,1.12,1.42,1.42,0,0,1-1.52.74c-.75,0-1.5.07-2.68.1s-1.43.74-1.93.74-1-.18-1.21.06,0,.63-.5.69c-1.18.15-1.68-2-2.55-2.43a1.86,1.86,0,0,0-1.12-.08.08.08,0,0,1,0,0,1.69,1.69,0,0,1,.17-.33c.36-.49,2.56-.82,4.11-2.37a55.64,55.64,0,0,0,4.44-6.34,34.91,34.91,0,0,0,3.74.49,3.58,3.58,0,0,1,2.66,1.49,2.84,2.84,0,0,1,.22.35C26,33.85,26.68,35,26.59,35.37Z'
      />
      <circle className='port-center' cx='26.15' cy='35.12' r='0.41' />
      <circle className='port-circle' cx='26.15' cy='35.12' r='0.68' />
      <circle className='port-circle' cx='26.15' cy='35.12' r='0.94' />
    </g>
  );
};

export default Valencia;
