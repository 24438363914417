import React from 'react';
import {
  StyledFooterBtnView,
  StyledMainFooter,
  StyledFooterBtn,
} from './index.styled';

const AppFooter = () => {
  return (
    <StyledMainFooter>
      <p>Copy right @ErkPort 2024</p>
      <StyledFooterBtnView>
        Powered by
        <StyledFooterBtn
          href='https://buyukresim.net/'
          target='_blank'
          type='link'
          color='primary'
        >
          BüyükResim
        </StyledFooterBtn>
      </StyledFooterBtnView>
    </StyledMainFooter>
  );
};

export default AppFooter;
