import React from 'react';
import {RegionType} from '..';

const OtherRegion: React.FC<RegionType> = ({isSelected, onClick}) => {
  return (
    <g
      onClick={onClick}
      className={`other region ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='other-subregion'
        d='M98.77,57.19l.22.12a12.06,12.06,0,0,1,.68-3.05,2.77,2.77,0,0,0,.26-1,2.7,2.7,0,0,1-.78-1.34c0-.19.13-1.39.45-1.52a1.9,1.9,0,0,1,.64,0c.05-.37.12-.91.17-1.4l-1.06-.45a4,4,0,0,0-.34,1,13.18,13.18,0,0,1-1.66,3.74L98.86,57Z'
      />
      <path
        className='other-subregion'
        d='M118.82,37.76c-.34-.08.16-1.91,0-2.24s-.59-1.75-.09-1.75,0-1,.55-1.08a2.14,2.14,0,0,0-.38-.78c-.31-.24-1.25.25-1.62-.68s0-1.06-.56-1.8-.81-1.5-1.49-1.19a6.72,6.72,0,0,1-2.2.07c-1,.92-3.23,1.55-3.9,1.3-.84-.31-2.46.1-3.68.1s-1.55-.88-2.49-.81-.93-.84-1.86-.84-.22-.47-3.15-.84a8.72,8.72,0,0,0-6.38,2c-.93.81-4.29,0-4.6.25S87,30.61,85.91,31s-3.58,0-4.42.38S80.34,33,81.06,33s1.09.28.87.59.59,1.19.18,1.56a1,1,0,0,0,.07,1.46c.5.38.59,1.06,0,1.15s1,.78,1.06,1.09,1.37.47,1.37.84.4.5,1,.32.91.71,1.31,1.33,2.71.19,2.71-.49.68-.88,1.71-.78,2.36,1.49,2.86,1.68,1.53-.38,2.18-.38,1.59-1,2-1.24,1,.72,1.62.4,1.24-.65,1.62-.43-.91,1.18-.56,1.68a.91.91,0,0,1,0,.71c1.29-.17,1.17-1.07,1.25-1.46s.42-.83.83-.5,1,.42,1.91,0,1-.66,1.33-.25,1.08.09,2.41.09,1.58-.92,2.83-.83a6.1,6.1,0,0,0,2.33-.34l.79.24c.32-.3.3-.63.93-.71,1-.12,1.37.62,1.87.37s2.08,1.45,2.22.35A2.86,2.86,0,0,0,118.82,37.76Zm-35.06-8c.75-.28,2.43.09,2.84-.28s-1.06-.84-1.59-1.28a2.1,2.1,0,0,1-.52-1.09.89.89,0,0,1-.85.16,4.07,4.07,0,0,0-2.15,0c-.23.09.19.88.19,1.4s-.61.65-.61,1-.22.42-.49.72a2.26,2.26,0,0,0,1.22.6C82.68,31,83,30,83.76,29.74Z'
      />
      <path
        className='other-subregion'
        d='M125,58.87s-3.24-.42-3.74-.42a4.19,4.19,0,0,1-2.5-.83,60.65,60.65,0,0,0-5.07-3.74c-.66-.33-3.4-2.32-4.15-2.41a12.71,12.71,0,0,0-1.75,0c-.91,0-.5.58-2.16.67s-2.57,1.16-2.08,1.33,2.08,1.57,1.58,1.82-.58,1-1.24,1-1.33.09-1.33.58a1.75,1.75,0,0,1-1.67,1.25,8.47,8.47,0,0,1-2.09-.93l-.22.61-.08.57c.15.44-.07,2.25.36,2.39s.71,1,1.71,2.09,3,4,3,4.73a3,3,0,0,0,1.61,2.33c1.25.84,1.25,1.87,1.9,2.37s.5,1.09.53,2.33a4.43,4.43,0,0,0,1.72,3.55,5.34,5.34,0,0,1,2.42,2.77,9.89,9.89,0,0,0,1.93,3.21c.72.71.31,1.08.72,1.61a6.29,6.29,0,0,1,.69,1.22,4.27,4.27,0,0,0,1-.53c.12-.24-.24-.9-.06-1.14a2.3,2.3,0,0,1,.9-.78c.36-.12.78.36,1.57.24a6.22,6.22,0,0,1,2.58.24c.18.18,2.41.06,3,.48s1.08.3,1.44-.54,2.89-2.65,3.25-2.83,3.07,0,4.15-.24,8.18-2.52,8.48-2.76a20.82,20.82,0,0,0,1.44-4.4c0-.42-.54-1.56-1.08-1.5a35.6,35.6,0,0,1-5.29-.6,3.73,3.73,0,0,1-1.59-2.26A2.76,2.76,0,0,1,134,69a1.59,1.59,0,0,1-1.76-1.21c-.41-.28-1.51-1.83-1.12-2.27s-.25-1.65-.94-2.27-2.08-1.77-2.15-2.49a3.76,3.76,0,0,0-.26-.89l-1.67-.09Z'
      />
      <path
        className='other-subregion'
        d='M79.75,43.53c-.5-.28-2.74.18-3.33-.38s-1.8.45-1.28.53a15.71,15.71,0,0,0,3.08.81c1.21,0,2.86-.56,2.83-.87S80.24,43.81,79.75,43.53ZM81.5,27.82c-.46.05-1.11.23-1,.49s-.31.5-.81.5a10.27,10.27,0,0,1-2.24-.38,10.77,10.77,0,0,0-2.82,0l0,.1c-.19.28-1.68,1-2,.9s-.42.47-1,.6a1.74,1.74,0,0,1-.34.05h-.16l2,4.77h0a6.19,6.19,0,0,1,.08,1.65,3.35,3.35,0,0,0,.68,2.69c-.09-.27-.16-.48-.11-.56s.58.12,1,.13a.56.56,0,0,0,.46-.19c.42-.48-.25-.84-.89-.84H74.3c-.35,0-.33-.24-.11-.41a.76.76,0,0,1,.86,0c.77.47,1.15-.12,1.52-.18s.47-1.4-.68-1.72-1,.85-1.75.07.69-.47.69-1.12S73.68,32.76,73.12,32s.78-1.15,1.09-.59,1.15.47,1.68.25-.56-1.21.4-1.59,1,.28,1.28.41.59-.78,1.58-.78a2.59,2.59,0,0,1,1.43.67c.27-.29.49-.47.49-.72s.61-.47.61-1A3.26,3.26,0,0,0,81.5,27.82Z'
      />
      <path
        className='other-subregion'
        d='M52,71.33h0L43,77,39.1,80.06s-2.51.89-3.25,1-2.07.45-2.07.45,0-1.63-1-2.22a7.16,7.16,0,0,0-2.37-.74A11.39,11.39,0,0,0,29,76.66c-.59-.6-19.39-13-20.42-13.62a1.14,1.14,0,0,1-.42-.38c0-1.38-.08-2.74,0-2.87a24.8,24.8,0,0,1,3.36-2.2c.48-.27,1.37.14,1.37-.41s1.17-.27,1.85-.41a1.25,1.25,0,0,0,1.1-1.23c0-.62,1-.48,1.58-.55s.55-.62,1-.62.21-.62.28-1.3.06-.55.68-.55,1.24-.35,1.24-.76,2.67-.13,3.08-.13a.41.41,0,0,0,.38-.22h0c.45-.25,6.87-3.77,11-4.85,2.74-.72,4.83,0,7.57.22l-.12,0c-.5,0,0,1.86.87,2.24a1.28,1.28,0,0,1,.87,1.36c0,.38,1.25.75,1.62,1.25a11,11,0,0,1,1,3.73.92.92,0,0,0,.19.56c-.24.16-.44.31-.47.44-.06.31.56,1,1,1.93s-.41,1.52-.06,2.21A1.13,1.13,0,0,1,47.35,62c-.31.37,0,1,0,1.31s.44.84.31,1-.91.73-.91,1,.47.62.93,1.32.16,1.09.23,1.63.86.86,1.48.86,1,.46,1.55.46S51.72,70.33,52,71.33Z'
      />
      <path
        className='other-subregion'
        d='M52,71.33h0L43,77,39.1,80.06s-2.51.89-3.25,1-2.07.45-2.07.45,0-1.63-1-2.22a7.16,7.16,0,0,0-2.37-.74A11.39,11.39,0,0,0,29,76.66c-.59-.6-19.39-13-20.42-13.62a1.14,1.14,0,0,1-.42-.38c0-1.38-.08-2.74,0-2.87a24.8,24.8,0,0,1,3.36-2.2c.48-.27,1.37.14,1.37-.41s1.17-.27,1.85-.41a1.25,1.25,0,0,0,1.1-1.23c0-.62,1-.48,1.58-.55s.55-.62,1-.62.21-.62.28-1.3.06-.55.68-.55,1.24-.35,1.24-.76,2.67-.13,3.08-.13a.41.41,0,0,0,.38-.22h0c.45-.25,6.87-3.77,11-4.85,2.74-.72,4.83,0,7.57.22l-.12,0c-.5,0,0,1.86.87,2.24a1.28,1.28,0,0,1,.87,1.36c0,.38,1.25.75,1.62,1.25a11,11,0,0,1,1,3.73.92.92,0,0,0,.19.56c-.24.16-.44.31-.47.44-.06.31.56,1,1,1.93s-.41,1.52-.06,2.21A1.13,1.13,0,0,1,47.35,62c-.31.37,0,1,0,1.31s.44.84.31,1-.91.73-.91,1,.47.62.93,1.32.16,1.09.23,1.63.86.86,1.48.86,1,.46,1.55.46S51.72,70.33,52,71.33Z'
      />

      <circle className='port-center' cx='101.46' cy='39.55' r='0.41' />
      <circle className='port-circle' cx='101.46' cy='39.55' r='0.68' />
      <circle className='port-circle' cx='101.46' cy='39.55' r='0.94' />
      <circle className='port-center' cx='81.82' cy='36.19' r='0.41' />
      <circle className='port-circle' cx='81.82' cy='36.19' r='0.68' />
      <circle className='port-circle' cx='81.82' cy='36.19' r='0.94' />
      <circle className='port-center' cx='85.86' cy='30.89' r='0.41' />
      <circle className='port-circle' cx='85.86' cy='30.89' r='0.68' />
      <circle className='port-circle' cx='85.86' cy='30.89' r='0.94' />
      <circle className='port-center' cx='86.39' cy='29.29' r='0.41' />
      <circle className='port-circle' cx='86.39' cy='29.29' r='0.68' />
      <circle className='port-circle' cx='86.39' cy='29.29' r='0.94' />

      <circle className='port-center' cx='75.54' cy='37.59' r='0.41' />
      <circle className='port-circle' cx='75.54' cy='37.59' r='0.68' />
      <circle className='port-circle' cx='75.54' cy='37.59' r='0.94' />

      <circle className='port-center' cx='98.39' cy='51.44' r='0.41' />
      <circle className='port-circle' cx='98.39' cy='51.44' r='0.68' />
      <circle className='port-circle' cx='98.39' cy='51.44' r='0.94' />
    </g>
  );
};

export default OtherRegion;
