import {useAuthMethod} from '@erkport/hooks/AuthHooks';
import React, {useContext, useEffect} from 'react';

import {Navigate} from 'react-router';

const Signout = () => {
  const {logout} = useAuthMethod();

  logout();

  return <Navigate to='/signin' />;
};

export default Signout;
