import React from 'react';
import {Button} from 'antd';
import {StyledFormGroupBtn} from './index.styled';
import useIntl from 'react-intl/lib/src/components/useIntl';

const AppFormGroupBtn = () => {
  const {messages} = useIntl();

  return (
    <StyledFormGroupBtn>
      <Button type='primary' htmlType='submit'>
        {messages['common.save'] as string}
      </Button>
      <Button>{messages['common.cancel'] as string}</Button>
    </StyledFormGroupBtn>
  );
};

export default AppFormGroupBtn;
