import React from 'react';

import {Spin, Space} from 'antd';

const Loading = () => {
  return (
    <Space
      direction='horizontal'
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        position: 'relative',
        padding: 20,
      }}
    >
      <Spin size='large' tip='Yükleniyor...' />
    </Space>
  );
};

export default Loading;
