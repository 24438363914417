export const drawChart = (container, data, field) => {
  document.getElementById(container).innerHTML = '';

  // create data set on our data
  var dataSet = window.anychart.data.set(data);

  // map data for the first series, take x from the zero column and value from the first column of data set
  var firstSeriesData = dataSet.mapAs({x: 0, value: 1});

  // create line chart
  var chart = window.anychart.line();

  // turn on chart animation
  chart.animation(true);

  // turn on the crosshair
  chart.crosshair().enabled(false);

  // set tooltip mode to point
  chart.tooltip().positionMode('point');

  // set yAxis title
  chart.yAxis().enabled(false);
  chart.xAxis().enabled(false);

  // create first series with mapped data
  var firstSeries = chart.line(firstSeriesData);
  firstSeries.name(field);
  firstSeries.hovered().markers().enabled(false);
  firstSeries.tooltip().fontSize(10).offsetX(5).offsetY(5);

  // turn the legend on
  chart.legend().enabled(false);

  chart.credits().enabled(false);

  // set container id for the chart
  chart.container(container);
  // initiate chart drawing

  chart.draw();
};
