import {formatNumberMK} from '@erkport/helpers/NumberFormatter';

export const drawChart = (container, data, exportTitle, importTitle, title) => {
  document.getElementById(container).innerHTML = '';

  var firstSeriesData = data.map((x) => ({x: x.x, value: x.export}));

  var secondSeriesData = data.map((x) => ({...x, value: x.import}));

  const maxData = Math.floor(
    Math.max(...data.map((x) => x.export), ...data.map((x) => x.import)),
  );
  // create line chart
  var chart = window.anychart.line();

  // turn on chart animation
  chart.animation(true);

  // turn on the crosshair
  chart.crosshair().enabled(false);

  // set tooltip mode to point
  chart.yAxis().enabled(false);

  // set yAxis title
  chart.yAxis().title('');
  chart.xAxis().title('');
  chart.yScale().maximum(Math.ceil(maxData * 1.5));

  var scale = window.anychart.scales.linear();
  scale.minimum(0).ticks({interval: 20});

  var firstSeries = chart.column(firstSeriesData);
  firstSeries.name(exportTitle);
  firstSeries.fill('#0a1f5c').stroke('rgb(29, 66, 138)');
  firstSeries
    .labels()
    .enabled(true)
    .fontSize(9)
    .fontColor('white')
    .position('center')
    .format(function () {
      return formatNumberMK(this.value, 0);
    });
  firstSeries.hovered().markers().enabled(false);

  var secondSeries = chart.line(secondSeriesData);
  secondSeries.name(importTitle);
  secondSeries.stroke('#8bbef2', 2);
  secondSeries.hovered().markers().enabled(false);

  chart.padding(0, 6, 0, 6);
  chart.barGroupsPadding(0.25);
  // turn the legend on
  chart
    .legend()
    .iconSize(8)
    .fontSize(12)
    .fontColor('black')
    .iconTextSpacing(5)
    .positionMode('inside')
    .align('right')
    .enabled(true);

  chart
    .title()
    .text(title)
    .fontSize(16)
    .fontColor('black')
    .align('left')
    .enabled(true);

  chart.credits().enabled(false);

  // set container id for the chart
  chart.container(container);
  // initiate chart drawing

  chart.draw();
};
