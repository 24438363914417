import React from 'react';
import {PortType} from '..';

const Barcelona: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='baleric-subregion'
        d='M32.6,28c-.86.35-1.21,1-2.16,1.18l-.14,0-.18,0c-.52-.41-1.92-.95-5.6-1.23a10.7,10.7,0,0,1-2.84-.54l.15-.22c.32-.49.64-.94.93-1.35A18.47,18.47,0,0,1,24,24.46l.08.05c.45.3,1.44.6,2,1s1.67.31,1.67-.15.6-.15,1,.23,1.82.45,2.5.6a13.5,13.5,0,0,0,2.16.07A1.39,1.39,0,0,1,32.6,28Z'
      />
      <circle className='port-center' cx='32.89' cy='27.46' r='0.41' />
      <circle className='port-circle' cx='32.89' cy='27.46' r='0.68' />
      <circle className='port-circle' cx='32.89' cy='27.46' r='0.94' />
    </g>
  );
};

export default Barcelona;
