import React from 'react';
import {RegionType} from '..';

const NorthAfrican: React.FC<RegionType> = ({isSelected, onClick}) => {
  return (
    <g
      onClick={onClick}
      className={`north-african region ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='north-african-subregion'
        d='M43.9,45.42c-.46.23-.81,1.2-1.25,1.36-2.74-.26-4.83-.94-7.57-.22-4.16,1.08-10.58,4.6-11,4.85a.91.91,0,0,0,.09-.61c-.06-.34-.41-1.37-.82-1.44s-.14-1.1-.14-1.92a3.46,3.46,0,0,0-.61-2.2,3.81,3.81,0,0,1-.35-.64,1,1,0,0,0,.79-.36,5.45,5.45,0,0,1,3.3-1.71c1,0,1.65-1.68,3.27-1.74s7.35-1,7.91-.47,2.24-.56,2.92-.59a13.09,13.09,0,0,1,2.15-.22,7.23,7.23,0,0,0,2,.39c-.19,1.24-.35,2.79,0,3.28A1.84,1.84,0,0,1,43.9,45.42Z'
      />
      <path
        className='north-african-subregion'
        d='M8.21,62.66c0-1.38-.08-2.74-.05-2.87a23.88,23.88,0,0,1,3.36-2.2c.48-.27,1.37.14,1.37-.41s1.17-.27,1.86-.41a1.25,1.25,0,0,0,1.09-1.24c0-.62,1-.48,1.58-.55s.55-.61,1-.61.21-.62.28-1.31.07-.55.68-.55,1.24-.34,1.24-.75,2.68-.14,3.09-.14.55-.48.48-.82-.41-1.37-.83-1.44-.13-1.1-.13-1.92a3.45,3.45,0,0,0-.62-2.2,4,4,0,0,1-.35-.65c-.75,0-1.66-.55-2.44-.57-1.09,0-2.77.53-3.58-.37s-1.37-1.19-2-.91-1.68,3.4-2.3,4.3a4.77,4.77,0,0,1-3.2,1.52c-.85,0-.94,1.28-1.59,1.81s-.41,1.37-1.19,2-.4,2.52,0,3.21S4.53,58.4,3.48,59,2.08,60.82.4,61.11a2.76,2.76,0,0,0-1.53.74H6.7C8.32,61.85,8,62.22,8.21,62.66Z'
      />

      <circle className='port-center' cx='7.21' cy='49.48' r='0.41' />
      <circle className='port-circle' cx='7.21' cy='49.48' r='0.68' />
      <circle className='port-circle' cx='7.21' cy='49.48' r='0.94' />

      <circle className='port-center' cx='40.77' cy='39.29' r='0.41' />
      <circle className='port-circle' cx='40.77' cy='39.29' r='0.68' />
      <circle className='port-circle' cx='40.77' cy='39.29' r='0.94' />

      <circle className='port-center' cx='26.77' cy='42.29' r='0.41' />
      <circle className='port-circle' cx='26.77' cy='42.29' r='0.68' />
      <circle className='port-circle' cx='26.77' cy='42.29' r='0.94' />

      <circle className='port-center' cx='14.7' cy='43.12' r='0.41' />
      <circle className='port-circle' cx='14.7' cy='43.12' r='0.68' />
      <circle className='port-circle' cx='14.7' cy='43.12' r='0.94' />
    </g>
  );
};

export default NorthAfrican;
