import React from 'react';
import ChartContainer from '@erkport/components/AppChartContainer';
import {drawChart} from './chart';

type VesselPortChartType = {
  containerName: string;
  data: any[];
  height: number;
};

const VesselPortChart: React.FC<VesselPortChartType> = ({
  containerName,
  data,
  height,
}) => {
  return (
    <ChartContainer
      containerName={containerName}
      drawChart={drawChart}
      data={data}
      height={height}
    />
  );
};

export default VesselPortChart;
