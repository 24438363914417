import Antwerp from './Antwerp';
import Ashdod from './Ashdod';
import Barcelona from './Barcelona';
import Casablanca from './Casablanca';
import DjenDjen from './DjenDjen';
import Fos from './Fos';
import Gemlik from './Gemlik';
import Iskenderun from './Iskenderun';
import Istanbul from './Istanbul';
import Izmir from './Izmir';
import Koper from './Koper';
import LeHavre from './LeHavre';
import Livorno from './Livorno';
import Monfalcone from './Monfalcone';
import Mostaganem from './Mostaganem';
import Pireaus from './Pireaus';
import Salerno from './Salerno';
import Savona from './Savona';
import Sete from './Sete';
import Setubal from './Setubal';
import StNazaire from './StNazaire';
import Tanger from './Tanger';
import Tarragona from './Tarragona';
import Toulon from './Toulon';
import Valencia from './Valencia';
import Vasto from './Vasto';
import Venice from './Venice';
import Vigo from './Vigo';
import Zeebrugge from './Zeebrugge';

export const cities = [
  {name: 'Antwerp', component: Antwerp},
  {name: 'Ashdod', component: Ashdod},
  {name: 'Barcelona', component: Barcelona},
  {name: 'Casablanca', component: Casablanca},
  {name: 'Djen-Djen', component: DjenDjen},
  {name: 'Haydarpasa', component: Istanbul},
  {name: 'Gemlik', component: Gemlik},
  {name: 'Iskenderun', component: Iskenderun},
  {name: 'Izmir', component: Izmir},
  {name: 'Koper', component: Koper},
  {name: 'Le Havre', component: LeHavre},
  {name: 'Monfalcone', component: Monfalcone},
  {name: 'Mostaganem', component: Mostaganem},
  {name: 'Pireaus', component: Pireaus},
  {name: 'Sete', component: Sete},
  {name: 'Setubal', component: Setubal},
  {name: 'St.Nazaire', component: StNazaire},
  {name: 'Tanger', component: Tanger},
  {name: 'Tarragona', component: Tarragona},
  {name: 'Toulon', component: Toulon},
  {name: 'Fos', component: Fos},
  {name: 'Valencia', component: Valencia},
  {name: 'Vasto', component: Vasto},
  {name: 'Salerno', component: Salerno},
  {name: 'Venice', component: Venice},
  {name: 'Livorno', component: Livorno},
  {name: 'Savona', component: Savona},
  {name: 'Vigo', component: Vigo},
  {name: 'Zeebrugge', component: Zeebrugge},
];
