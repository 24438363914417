import styled from 'styled-components';

export const StyledAppLogo = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  height: 56px !important;
  width: auto !important;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      height: 45%;
    }
  }

  @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
    height: 71px !important;
  }
`;
