import React from 'react';
import {Button, Col, Form, Input} from 'antd';
import AppRowContainer from '@erkport/components/AppRowContainer';
import IntlMessages from '@erkport/helpers/IntlMessages';
import {
  StyledUserProfileForm,
  StyledUserProfileFormTitle,
  StyledUserProfileGroupBtn,
} from '../index.styled';
import AppFormGroupBtn from '@erkport/components/AppFormGroupBtn';
import {updatePassword} from '@erkport/services/profile';
import {useInfoViewActionsContext} from '@erkport/context/AppContextProvider/InfoViewContextProvider';
import useIntl from 'react-intl/lib/src/components/useIntl';

const ChangePassword = () => {
  const {showMessage, fetchError, fetchSuccess} = useInfoViewActionsContext();
  const {messages} = useIntl();

  const onFinish = (values: any) => {
    updatePassword(values)
      .then(() => {
        fetchSuccess();
        showMessage(messages['api.messages.passwordUpdated'] as string);
      })
      .catch((error) => {
        fetchError(messages[error.response.data.message] as string);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <StyledUserProfileForm
      initialValues={{remember: true}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <AppRowContainer gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='oldPassword'
            rules={[
              {
                required: true,
                message: messages['forms.oldPasswordRequired'] as string,
              },
            ]}
          >
            <Input.Password
              placeholder={messages['forms.oldPassword'] as string}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} />
        <Col xs={24} md={12}>
          <Form.Item
            name='newPassword'
            rules={[
              {
                required: true,
                message: messages['forms.newPasswordRequired'] as string,
              },
            ]}
          >
            <Input.Password
              placeholder={messages['forms.newPassword'] as string}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='newPasswordConfirmation'
            rules={[
              {
                required: true,
                message: messages['forms.confirmPasswordRequired'] as string,
              },
              ({getFieldValue}) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    messages['forms.confirmPasswordNotMatched'],
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={messages['forms.confirmNewPassword'] as string}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={24}>
          <AppFormGroupBtn />
        </Col>
      </AppRowContainer>
    </StyledUserProfileForm>
  );
};

export default ChangePassword;
