import {CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons';
import {Col, Radio, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import TrendLine from './trendLine';
import Loadable from '@erkport/components/AppLoadable';
import {
  formatCur,
  formatNumberK,
  formatNumberMK,
} from '@erkport/helpers/NumberFormatter';
import JsonToExcelExporter from '@erkport/components/JsonToExcelExporter';
import moment from '@erkport/services/moment';
import AppRowContainer from '@erkport/components/AppRowContainer';
import {LastMonthsType} from '@erkport/helpers/DateHelper';
import AppSelect from '@erkport/components/AppSelect';
import {isNull} from '@erkport/helpers/Validator';
import {MessageFormatElement, useIntl} from 'react-intl';
import {monthShortNames} from '@erkport/constants/AppConst';

const HEAT_COLORS = ['#6CB4EE', '#3E8EDE', '#0066b2', '#1D428A', '#132257'];

const CUSTOMER_TREND_CARDS = {
  all: 'customer.trend.cards.all',
  increasing: 'customer.trend.cards.increasing',
  decreasing: 'customer.trend.cards.decreasing',
  domesticSales: 'customer.trend.cards.domesticSales',
  fieldSales: 'customer.trend.cards.fieldSales',
  keyCustomer: 'customer.trend.cards.keyCustomer',
  pcc: 'customer.trend.cards.pcc',
  pctc: 'customer.trend.cards.pctc',
};

const getHeatColor = (max, min, value, heatType) => {
  const scale = (max - min) / 5;
  if (value >= 4 * scale) {
    return HEAT_COLORS[4];
  } else if (value >= 3 * scale) {
    return HEAT_COLORS[3];
  } else if (value >= 2 * scale) {
    return HEAT_COLORS[2];
  } else if (value >= scale) {
    return HEAT_COLORS[1];
  } else {
    return HEAT_COLORS[0];
  }
};

const getColumns = (
  min: number,
  max: number,
  heatType: string,
  last12Months: LastMonthsType[],
  field: string,
  sourceName: string,
  setSelectedRow: any,
  selectedRow: string,
  messages: any,
) => {
  return [
    {
      title: '',
      dataIndex: '',
      key: 'selected',
      render: (_, row) => (
        <Radio
          onChange={() => setSelectedRow(row.name)}
          checked={selectedRow == row.name}
        ></Radio>
      ),
      onCell: (row) => ({
        style: {
          opacity: selectedRow == row.name ? 0.7 : 1,
          backgroundColor: selectedRow == row.name ? 'white' : '',
        },
      }),
    },
    {
      title: messages['common.order'],
      dataIndex: 'order',
      key: 'order',
      sorter: (a, b) => (a.order > b.order ? 1 : -1),
      onCell: (row) => ({
        style: {
          opacity: selectedRow == row.name ? 0.7 : 1,
          backgroundColor: selectedRow == row.name ? 'white' : '',
        },
      }),
    },
    {
      title: sourceName,
      dataIndex: 'name',
      key: 'name',
      onCell: (row) => ({
        style: {
          fontSize: 12,
          opacity: selectedRow == row.name ? 0.7 : 1,
          backgroundColor: selectedRow == row.name ? 'white' : '',
        },
      }),
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (_, row) => (
        <a
          onClick={() => setSelectedRow(row.name)}
          style={{color: selectedRow == row.name ? '#1677ff' : 'gray'}}
        >
          {row.name.toUpperCase()}
        </a>
      ),
    },
    ...last12Months.map((x) => ({
      title: messages[monthShortNames[x.month - 1]],
      dataIndex: `${x.year}-${x.month}`,
      key: `${x.year}-${x.month}`,
      align: 'center',
      render: (_, row) =>
        field == 'totalSalesEur' || field == 'totalProfitEur'
          ? formatNumberMK(row[`${x.year}-${x.month}`], 2)
          : formatCur(row[`${x.year}-${x.month}`], null, 2),
      onCell: (row) => ({
        style: {
          fontSize: 12,
          minWidth: 60,
          borderRight: '1px solid #f0f0f0',
          color: '#f0f0f0',
          padding: 0,
          opacity: selectedRow == row.name ? 0.7 : 1,
          backgroundColor:
            heatType === 'row'
              ? getHeatColor(
                  Number(row.max),
                  Number(row.min),
                  Number(row[`${x.year}-${x.month}`]),
                  heatType,
                )
              : getHeatColor(
                  Number(max || 0),
                  Number(min || 0),
                  Number(row[`${x.year}-${x.month}`]),
                  heatType,
                ),
        },
      }),
      sorter: (a, b) =>
        a[`${x.year}-${x.month}`] > b[`${x.year}-${x.month}`] ? 1 : -1,
    })),
    {
      title: messages['common.total'],
      dataIndex: 'total',
      key: `total`,
      align: 'center',
      onCell: (row) => ({
        style: {
          fontSize: 12,
          minWidth: 60,
          padding: 0,
          opacity: selectedRow == row.name ? 0.7 : 1,
          backgroundColor: selectedRow == row.name ? 'white' : '',
        },
      }),
      render: (_, row) =>
        field == 'totalSalesEur' || field == 'totalProfitEur'
          ? formatNumberMK(row.total, 2)
          : formatCur(row.total, null, 1),
      sorter: (a, b) => (a.total > b.total ? 1 : -1),
    },
    {
      title: messages['charts.last12Months'],
      width: 150,
      align: 'center',
      onCell: (row) => ({
        style: {
          padding: 0,
          opacity: selectedRow == row.name ? 0.7 : 1,
          backgroundColor: selectedRow == row.name ? 'white' : '',
        },
      }),
      render: (_, {name, max, min, key, order, total, ...monthData}, index) => (
        <TrendLine
          container={index}
          field={messages[`common.${field}`]}
          data={Object.entries(monthData).map(([key, value]) => [
            key,
            Math.round(value),
          ])}
        />
      ),
    },
    {
      title: messages['common.direction'],
      align: 'center',
      width: 50,
      onCell: (row) => ({
        style: {
          opacity: selectedRow == row.name ? 0.7 : 1,
          backgroundColor: selectedRow == row.name ? 'white' : '',
        },
      }),
      render: (_, {name, max, min, key, order, total, ...monthData}) => {
        const avg =
          Object.values(monthData)
            .slice(-5, -2)
            .reduce((total, cur) => Number(total || 0) + Number(cur || 0), 0) /
          3;
        return Object.values(monthData).slice(-2)[0] > avg ? (
          <div style={{textAlign: 'center'}}>
            <CaretUpOutlined style={{color: '#3E8EDE', fontSize: 25}} />
          </div>
        ) : Object.values(monthData).slice(-2)[0] < avg ? (
          <div style={{textAlign: 'center'}}>
            <CaretDownOutlined style={{color: '#e32636', fontSize: 25}} />
          </div>
        ) : (
          <h3>-</h3>
        );
      },
    },
  ];
};

type TrendTableType = {
  data: any[];
  last12Months: LastMonthsType[];
  selectedCard: any;
  source: string;
  setSelectedRow: any;
  selectedRow: string;
};

const TrendTable: React.FC<TrendTableType> = ({
  data,
  last12Months,
  selectedCard,
  source,
  setSelectedRow,
  selectedRow,
}) => {
  const [field, setField] = useState('totalSalesEur');
  const [heatType, setHeatType] = useState('row');

  const {messages} = useIntl();
  const sourceName = messages[`common.${source.toLocaleLowerCase()}`];
  const dataNames =
    selectedCard?.data ||
    Array.from(new Set(data.flatMap((x) => x.data).map((x) => x[source])));

  const tableData = dataNames
    .map((dataName) => ({
      name: dataName,
      ...last12Months.reduce<Record<any, LastMonthsType>>((total, cur) => {
        const monthData =
          (data
            .filter((x) => x.year == cur.year && x.month == cur.month)[0]
            ?.data?.filter((x) => x[source] == dataName)[0] || {})[field] ||
          '0';

        return {
          ...total,
          [`${cur.year}-${cur.month}`]: Number(monthData),
          max: Math.max(Number(monthData || 0), Number(total.max || 0)),
          min: Math.min(Number(monthData), Number(total.min || 0)),
          key: dataName,
        };
      }, {}),
    }))
    .map((x) => ({
      ...x,
      total: last12Months.reduce(
        (total, month) => x[`${month.year}-${month.month}`] + total,
        0,
      ),
    }))
    .sort((a, b) => (a.total < b.total ? 1 : -1))
    .map((x, i) => ({...x, order: i + 1}));

  const minTableData = Math.min(...tableData.map((x) => x.min));
  const maxTableData = Math.max(...tableData.map((x) => x.max));

  useEffect(() => {
    setSelectedRow(tableData[0]?.name);
  }, []);

  const _columns = getColumns(
    minTableData,
    maxTableData,
    heatType,
    last12Months,
    field,
    sourceName.toString(),
    setSelectedRow,
    selectedRow,
    messages,
  );

  return (
    <Loadable isLoading={isNull(tableData) || isNull(_columns)}>
      <AppRowContainer>
        <Col
          xs={6}
          style={{display: 'flex', alignItems: 'center', padding: 10}}
        >
          <Radio.Group
            value={heatType}
            onChange={(e) => setHeatType(() => e.target.value)}
          >
            <Radio value='row'>{messages['common.applyToRow'] as string}</Radio>
            <Radio value='table'>
              {messages['common.applyToTable'] as string}
            </Radio>
          </Radio.Group>
        </Col>
        <Col
          xs={3}
          style={{display: 'flex', alignItems: 'center', padding: 10}}
        >
          <AppSelect
            options={[
              {
                value: 'totalSalesEur',
                label: messages['common.sales'] as string,
              },
              {
                value: 'totalProfitEur',
                label: messages['common.profit'] as string,
              },
              {
                value: 'profitability',
                label: messages['common.profitabilityRatio'] as string,
              },
            ]}
            onChange={(x) => setField(x)}
            defaultValue={field}
          />
        </Col>
        <Col
          xs={15}
          style={{display: 'flex', justifyContent: 'right', padding: 10}}
        >
          <JsonToExcelExporter
            data={tableData?.map(
              ({order, max, min, key, name, ...monthData}) => ({
                [sourceName.toString()]: name,
                ...Object.entries(monthData).reduce(
                  (result, [key, value]) => ({
                    ...result,
                    [moment.months()[Number(key.split('-')[1]) - 1]]: value,
                  }),
                  {},
                ),
              }),
            )}
            fileName={
              selectedCard?.type && selectedCard?.type != 'all'
                ? `profitability_${source}_trend_${
                    messages[CUSTOMER_TREND_CARDS[selectedCard?.type]]
                  }`
                : `profitability_${source}_trend`
            }
          />
        </Col>
        <Col xs={24}>
          <Table
            size='small'
            pagination={{defaultPageSize: 50, pageSizeOptions: [20, 50, 100]}}
            columns={_columns as any[]}
            dataSource={tableData}
            showSorterTooltip={false}
          />
        </Col>
      </AppRowContainer>
    </Loadable>
  );
};

export default TrendTable;
