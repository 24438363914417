import AppRowContainer from '@erkport/components/AppRowContainer';
import {getPorts} from '@erkport/services/erkport';
import {Col} from 'antd';
import React, {useEffect, useState} from 'react';
import ImportExportChart from '../importExportChart';
import InfoCard from './infoCard';

type ClusterDetailType = {
  ports: any[];
  clusterData: any[];
};

const ClusterDetail: React.FC<ClusterDetailType> = ({ports, clusterData}) => {
  return (
    <>
      {ports?.map((x) => (
        <AppRowContainer key={x.name}>
          <Col xs={3}>
            <InfoCard iconPath='' count='%29' details={x.name} />
          </Col>
          <Col xs={10}>
            <ImportExportChart
              containerName={`europe-cluster-detail-chart-${x.name}`}
              data={[]}
              height={200}
            />
          </Col>
        </AppRowContainer>
      ))}
    </>
  );
};

export default ClusterDetail;
