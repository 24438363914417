import React from 'react';
import {RegionType} from '..';

const Adriatic: React.FC<RegionType> = ({isSelected, onClick}) => {
  return (
    <g
      onClick={onClick}
      className={`adriatic region ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='adriatic-subregion'
        d='M53.79,17.93a2.14,2.14,0,0,1,1.35-.17c.24.09.32.35.36.62A11.08,11.08,0,0,0,57,18.1c.37-.14,1.21.32,1.3,0s.38-.93.75-.93.14-1,.51-1,1.31-.88,1.31-.88-.37-.75-.51-.8-.61.47-1.45.47-1.45.65-1.82.65-1.54-.37-2.33-.42l-.85-.06A18.25,18.25,0,0,0,53.79,17.93Z'
      />
      <path
        className='adriatic-subregion'
        d='M74.18,40.73c-.44.21-1.25-1-2-.94a1.2,1.2,0,0,1-1.09-1.46c.18-.62-1.25-1.18-.44-1.77s2.24.18,2.24-.19-1.4-1.24-1.9-.69-1.58.29-1.49-.49a1.77,1.77,0,0,0-1.12-1.81l-.09-.05c.17-.5.29-1.07.6-1.13.47-.09.19-.47.52-.8a3.55,3.55,0,0,0,.74-1.21c0-.17.49-.12,1-.14l2,4.77h0a6.19,6.19,0,0,1,.08,1.65,3.35,3.35,0,0,0,.68,2.69C74.12,39.75,74.48,40.58,74.18,40.73Z'
      />
      <path
        className='adriatic-subregion'
        d='M60.61,28.87c-.81-.5-.28-1.31-1.56-1.25s-2.8-1.52-3.27-3.36-2.45-1.93-3-3,.47-1.21,0-2.11c-.24-.5.29-.95.93-1.2a18.31,18.31,0,0,1,.11-2.79,3.54,3.54,0,0,1-1.07-.21c-.24-.14-.28-.89-.42-.89s-1.45,0-1.68.23-.94.66-1.31.42l-.26-.14-.41.38s-.06.5-.31.5-.62-.19-.62.24a.32.32,0,0,1,0,.14A36.13,36.13,0,0,0,61.36,31.87a1.41,1.41,0,0,1,1.83-.14c1.09.69,1.53,1.15,1.59.1S61.42,29.37,60.61,28.87Z'
      />

      <circle className='port-center' cx='58.02' cy='27.27' r='0.41' />
      <circle className='port-circle' cx='58.02' cy='27.27' r='0.68' />
      <circle className='port-circle' cx='58.02' cy='27.27' r='0.94' />
      <circle className='port-center' cx='53' cy='20.67' r='0.41' />
      <circle className='port-circle' cx='53' cy='20.67' r='0.68' />
      <circle className='port-circle' cx='53' cy='20.67' r='0.94' />
      <circle className='port-center' cx='53' cy='18.67' r='0.41' />
      <circle className='port-circle' cx='53' cy='18.67' r='0.68' />
      <circle className='port-circle' cx='53' cy='18.67' r='0.94' />
      <circle className='port-center' cx='55' cy='17.67' r='0.41' />
      <circle className='port-circle' cx='55' cy='17.67' r='0.68' />
      <circle className='port-circle' cx='55' cy='17.67' r='0.94' />
    </g>
  );
};

export default Adriatic;
