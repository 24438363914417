import React, {useState} from 'react';
import {StyledAppSelect, StyledOption} from './index.styled';

type AppSelectProps = {
  id?: string;
  options:
    | {value: number; label: string}[]
    | {value: string; label: string}[]
    | string[]
    | number[];
  onChange: (e: any) => void;
  defaultValue?: any;
  valueKey?: string;
  labelKey?: string;
  placeholder?: any;
};

const AppSelect: React.FC<AppSelectProps> = ({
  id,
  options = [],
  onChange,
  defaultValue,
  valueKey = 'value',
  labelKey = 'label',
  placeholder,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (value: any) => {
    setValue(value);
    onChange(value);
  };

  return (
    <StyledAppSelect
      id={id}
      defaultValue={options[0]?.[valueKey] || options[0]}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
    >
      {options.map((menu, index) => (
        <StyledOption
          key={index}
          value={menu[valueKey] ? menu[valueKey] : menu}
        >
          {menu[labelKey] ? menu[labelKey] : menu}
        </StyledOption>
      ))}
    </StyledAppSelect>
  );
};

export default AppSelect;
