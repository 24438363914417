import {authRole} from '@erkport/constants/AppConst';

export const getUserFromJwtAuth = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName || `${user.firstName} ${user.lastName}`,
      email: user.email,
      photoURL: user.photoURL,
      role: user.role,
    };
  return user;
};
