import React, {
  Children,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import PageFilter from './PageFilter';
import moment from '@erkport/services/moment';
import {Moment} from 'moment';

export interface PageWithFilterProps {
  compareYear?: any;
  year?: any;
  month?: any;
  week?: any;
  port?: any;
  vessel?: any;
  customer?: any;
  date?: Moment;
  loadUnit?: string;
}

interface PageWrapperProps {
  yearsProvider?: () => Promise<any[]>;
  monthsProvider?: (year?: any) => Promise<any[]>;
  weeksProvider?: (year?: any) => Promise<any[]>;
  portsProvider?: (year?: any, month?: any, week?: any) => Promise<any[]>;
  vesselsProvider?: (year?: any, month?: any, week?: any) => Promise<any[]>;
  customersProvider?: (year?: any, month?: any, week?: any) => Promise<any[]>;
  hasLoadUnit?: boolean;
  hasDate?: boolean;
  dateConfig?: {
    allowClear?: boolean;
    labelKey?: string;
  };
  hasCompareYear?: boolean;
  children?: (
    props: PageWithFilterProps,
  ) =>
    | ReactNode
    | ReactNode[]
    | ReactElement
    | JSX.Element
    | ReactElement[]
    | JSX.Element[];
}

const PageWrapper = ({
  yearsProvider,
  monthsProvider,
  weeksProvider,
  portsProvider,
  vesselsProvider,
  customersProvider,
  hasLoadUnit,
  hasDate,
  dateConfig = {
    allowClear: true,
    labelKey: 'common.date',
  },
  hasCompareYear,
  ...props
}: PageWrapperProps) => {
  const ChildrenElement = props.children;
  const [compareYear, setCompareYear] = useState('');
  const [yearState, setYearState] = useState({year: '', years: []});
  const [monthState, setMonthState] = useState({month: '', months: []});
  const [weekState, setWeekState] = useState({week: '', weeks: []});
  const [portState, setPortState] = useState({port: '', ports: []});
  const [vesselState, setVesselState] = useState({vessel: '', vessels: []});
  const [customerState, setCustomerState] = useState({
    customer: '',
    customers: [],
  });
  const [date, setDate] = useState(moment());
  const [loadUnit, setLoadUnit] = useState('M3');
  const currentYear = moment().format('Y');
  const currentMonth = moment().format('M');

  const executeDataProviders = (year?, month?, week?) => {
    if (portsProvider) {
      portsProvider(year, month, week).then((ports) =>
        setPortState({port: ports[0], ports}),
      );
    }
    if (vesselsProvider) {
      vesselsProvider(year, month, week).then((vessels) =>
        setVesselState({vessel: vessels[0], vessels}),
      );
    }
    if (customersProvider) {
      customersProvider(year, month, week).then((customers) =>
        setCustomerState({customer: customers[0], customers}),
      );
    }
  };

  useEffect(() => {
    if (yearsProvider) {
      yearsProvider().then((years) => {
        setYearState({
          year: years.some((y) => y == currentYear)
            ? currentYear
            : Math.max(...years).toString(),
          years,
        });
        setCompareYear(
          years.some((y) => Number(y) == Number(currentYear) - 1)
            ? (Number(currentYear) - 1).toString()
            : Math.min(...years).toString(),
        );
      });
    }
    if (monthsProvider) {
      monthsProvider().then((months) => {
        const monthsData = months.filter(
          (m) => Number(m) <= Number(currentMonth),
        );
        setMonthState({
          month: Math.max(...monthsData).toString(),
          months: monthsData,
        });
      });
    }
    if (weeksProvider) {
      weeksProvider().then((weeks) =>
        setWeekState({week: Math.max(...weeks).toString(), weeks}),
      );
    }
    executeDataProviders();
  }, []);

  useEffect(() => {
    if (monthsProvider && yearState?.year) {
      monthsProvider(yearState.year).then((months) => {
        let monthsData = months;
        if (Number(yearState.year) == Number(currentYear)) {
          monthsData = monthsData.filter(
            (m) => Number(m) <= Number(currentMonth),
          );
        }
        setMonthState({
          month: Math.max(...monthsData).toString(),
          months: monthsData,
        });
      });
    }
    if (weeksProvider && yearState?.year) {
      weeksProvider(yearState.year).then((weeks) =>
        setWeekState({week: Math.max(...weeks).toString(), weeks}),
      );
    }
    executeDataProviders(yearState.year, monthState.month, weekState.week);
  }, [yearState.year]);

  useEffect(() => {
    executeDataProviders(yearState.year, monthState.month, weekState.week);
  }, [monthState.month]);

  useEffect(() => {
    executeDataProviders(yearState.year, monthState.month, weekState.week);
  }, [weekState.week]);

  return (
    <>
      <PageFilter
        yearState={yearState}
        monthState={monthState}
        weekState={weekState}
        portState={portState}
        vesselState={vesselState}
        customerState={customerState}
        setYear={(year) => setYearState((state) => ({...state, year}))}
        setMonth={(month) => setMonthState((state) => ({...state, month}))}
        setWeek={(week) => setWeekState((state) => ({...state, week}))}
        setPort={(port) => setPortState((state) => ({...state, port}))}
        setVessel={(vessel) => setVesselState((state) => ({...state, vessel}))}
        setCustomer={(customer) =>
          setCustomerState((state) => ({...state, customer}))
        }
        loadUnit={loadUnit}
        setLoadUnit={hasLoadUnit && setLoadUnit}
        date={date}
        setDate={hasDate && setDate}
        dateConfig={dateConfig}
        compareYear={compareYear}
        setCompareYear={hasCompareYear && setCompareYear}
      />
      {ChildrenElement({
        ...yearState,
        ...monthState,
        ...weekState,
        ...portState,
        ...vesselState,
        ...customerState,
        date,
        loadUnit,
        compareYear,
      })}
    </>
  );
};

export default PageWrapper;
