import React from 'react';
import {
  StyledAuth,
  StyledAuthCard,
  StyledAuthCardHeader,
  StyledAuthMainContent,
  StyledAuthWrap,
  StyledMainAuthScrollbar,
} from './AuthWrapper.styled';
import AppLogo from '../../layout/BaseLayout/components/AppLogo';
import AppAnimateGroup from '../../components/AppAnimateGroup';

type Props = {
  children: React.ReactNode;
};
const AuthWrapper: React.FC<Props> = ({children}) => {
  return (
    <StyledAuth>
      <StyledMainAuthScrollbar>
        <AppAnimateGroup
          type='scale'
          animateStyle={{flex: 1}}
          delay={0}
          style={{height: '100%'}}
          interval={10}
          duration={200}
        >
          <StyledAuthWrap key={'wrap'}>
            <StyledAuthCard>
              <StyledAuthMainContent>
                <StyledAuthCardHeader>
                  <AppLogo />
                </StyledAuthCardHeader>
                {children}
              </StyledAuthMainContent>
            </StyledAuthCard>
          </StyledAuthWrap>
        </AppAnimateGroup>
      </StyledMainAuthScrollbar>
    </StyledAuth>
  );
};

export default AuthWrapper;
