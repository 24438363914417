import React from 'react';
import styled from 'styled-components';
import {Drawer, DrawerProps} from 'antd';
import MainSidebar from './MainSidebar';
import AppScrollbar from '../../../../components/AppScrollbar';

export const StyledAppDrawer = styled((props: DrawerProps) => (
  <Drawer contentWrapperStyle={{width: 300}} {...props} />
))`
  width: 300px !important;

  .ant-drawer-content-wrapper {
    width: 300px !important;
  }

  & .ant-drawer-body {
    padding: 0;
  }

  & .cr-user-info {
    padding-top: 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid ${({theme}) => theme.palette.borderColor};
  }
`;

export const StyledAppDrawerLayoutSidebar = styled(MainSidebar)`
  &.ant-layout-sider {
    flex: 0 0 auto !important;
    max-width: none !important;
    min-width: 0 !important;
    width: 100% !important;
    transition: all 0.1s linear;
    border-right: 1px solid ${({theme}) => theme.palette.borderColor};
  }

  & .ant-layout-sider-trigger {
    display: none;
  }

  &.ant-layout-sider-has-trigger {
    padding-bottom: 0;
  }

  // Sidebar Collapsed
  &.ant-layout-sider-collapsed {
    width: 0 !important;
    transition: all 200ms linear;

    @media screen and (min-width: ${({theme}) => theme.breakpoints.lg}px) {
      width: 4rem !important;
    }

    & + .app-DrawerLayout-main {
      width: 100% !important;
      transition: all 200ms linear;

      @media screen and (min-width: ${({theme}) => theme.breakpoints.lg}px) {
        width: calc(100% - 4rem) !important;
      }
    }

    & .cr-user-info {
      padding-left: 12px;
      padding-right: 12px;
    }

    & .cr-user-info-content {
      opacity: 0;
      visibility: hidden;
      width: 0;
      padding-left: 0;
      padding-right: 0;
    }

    & .ant-menu-item-group-title {
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
      padding: 0;
    }
  }

  &.drawerLayout-sidebar-img-background {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: inherit;
      z-index: 1;
      opacity: 0.5;
    }

    & > div {
      position: relative;
      z-index: 3;
    }

    & .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
      background-color: transparent;
      color: inherit;
    }

    & .app-main-sidebar-menu.ant-menu-dark .ant-menu-item-group-title,
    & .app-main-sidebar-menu .ant-menu-item a {
      color: inherit;
    }
  }
`;

export const StyledAppDrawerSidebarScrollbar = styled(AppScrollbar)`
  height: calc(100vh - 56px);

  .appMainFixedFooter & {
    height: calc(100vh - 102px);
  }

  @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
    height: calc(100vh - 71px);

    .appMainFixedFooter & {
      height: calc(100vh - 116px);
    }
  }
`;
