import React, {createContext, ReactNode, useContext, useState} from 'react';
import defaultPageFilter from '@erkport/constants/defaultPageFilter';

export interface PageFilterData {
  year: string;
  month: string;
  week: string;
  day: string;
  date: string;
  yearToCompare: string;
  vessel: string;
}

export interface PageFilterActions {
  updateYear: (year: string) => void;
  updateMonth: (month: string) => void;
  updateWeek: (week: string) => void;
  updateDay: (day: string) => void;
  updateDate: (date: string) => void;
  updateYearToCompare: (yearToCompare: string) => void;
  updateVessel: (vessel: string) => void;
}

export const PageFilterContext = createContext<PageFilterData>({
  year: defaultPageFilter.year,
  month: defaultPageFilter.month,
  week: defaultPageFilter.week,
  day: defaultPageFilter.day,
  date: defaultPageFilter.date,
  yearToCompare: defaultPageFilter.yearToCompare,
  vessel: defaultPageFilter.vessel,
});

const PageFilterActionsContext = createContext<PageFilterActions>({
  updateYear: () => {},
  updateMonth: () => {},
  updateWeek: () => {},
  updateDay: () => {},
  updateDate: () => {},
  updateYearToCompare: () => {},
  updateVessel: () => {},
});

export const usePageFilterContext = () => useContext(PageFilterContext);

export const usePageFilterActionsContext = () =>
  useContext(PageFilterActionsContext);
type PageFilterContextProviderProps = {
  children: ReactNode;
};

const PageFilterContextProvider: React.FC<PageFilterContextProviderProps> = ({
  children,
}) => {
  const [year, updateYear] = useState<string>(defaultPageFilter.year);
  const [month, updateMonth] = useState<string>(defaultPageFilter.month);
  const [week, updateWeek] = useState<string>(defaultPageFilter.week);
  const [day, updateDay] = useState<string>(defaultPageFilter.day);
  const [date, updateDate] = useState<string>(defaultPageFilter.date);
  const [yearToCompare, updateYearToCompare] = useState<string>(
    defaultPageFilter.yearToCompare,
  );
  const [vessel, updateVessel] = useState<string>(defaultPageFilter.vessel);

  return (
    <PageFilterContext.Provider
      value={{year, month, week, day, date, yearToCompare, vessel}}
    >
      <PageFilterActionsContext.Provider
        value={{
          updateYear,
          updateMonth,
          updateWeek,
          updateDay,
          updateDate,
          updateYearToCompare,
          updateVessel,
        }}
      >
        {children}
      </PageFilterActionsContext.Provider>
    </PageFilterContext.Provider>
  );
};

export default PageFilterContextProvider;
