export const authRole = {
  Admin: ['admin'],
  User: ['user', 'user_role', 'admin'],
};

export const defaultUser: any = {
  uid: '1',
  displayName: 'User Name',
  email: 'user@mail.com',
  token: 'access-token',
  role: 'user_role',
  photoURL: '/assets/images/avatar/A11.jpg',
};
export const initialUrl = '/vessel';

export const monthNames = [
  'months.january',
  'months.february',
  'months.march',
  'months.april',
  'months.may',
  'months.june',
  'months.july',
  'months.august',
  'months.september',
  'months.october',
  'months.november',
  'months.december',
];
export const monthShortNames = [
  'months.short.jan',
  'months.short.feb',
  'months.short.mar',
  'months.short.apr',
  'months.short.may',
  'months.short.jun',
  'months.short.jul',
  'months.short.aug',
  'months.short.sep',
  'months.short.oct',
  'months.short.nov',
  'months.short.dec',
];
export const monthsInitials = [
  'months.initial.jan',
  'months.initial.feb',
  'months.initial.mar',
  'months.initial.apr',
  'months.initial.may',
  'months.initial.jun',
  'months.initial.jul',
  'months.initial.aug',
  'months.initial.sep',
  'months.initial.oct',
  'months.initial.nov',
  'months.initial.dec',
];

export const weekDays: string[] = [
  'Pazartesi',
  'Salı',
  'Çarşamba',
  'Perşembe',
  'Cuma',
  'Cumartesi',
  'Pazar',
];
export const weekDaysShort: string[] = [
  'P.tesi',
  'Salı',
  'Çarş.',
  'Perş.',
  'Cuma',
  'C.tesi',
  'Pazar',
];
export const weekDaysInitial: string[] = ['P', 'S', 'Ç', 'P', 'C', 'C', 'P'];

type WeekDayFromIndexFuncType = (dayIndex: number) => string;

export const weekDayFromIndex: WeekDayFromIndexFuncType = (dayIndex) =>
  dayIndex <= 0 ? weekDays[dayIndex + 6] : weekDays[dayIndex - 1];
export const weekDayShortFromIndex: WeekDayFromIndexFuncType = (dayIndex) =>
  dayIndex <= 0 ? weekDaysShort[dayIndex + 6] : weekDaysShort[dayIndex - 1];
export const weekDayInitialFromIndex: WeekDayFromIndexFuncType = (dayIndex) =>
  dayIndex <= 0 ? weekDaysInitial[dayIndex + 6] : weekDaysInitial[dayIndex - 1];
