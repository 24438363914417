import React from 'react';
import {PortType} from '..';

const Fos: React.FC<PortType> = ({importExportStatus, isSelected, onClick}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='baleric-subregion'
        d='M37.89,23.72l-.2.25a8.27,8.27,0,0,0-2.34-.37.69.69,0,0,0-.25,0,3.66,3.66,0,0,0-2.56-2.84A9.68,9.68,0,0,1,29.81,19l.2-.17c1.06-.94,2.15-1.89,3.21-2.8l.64.73c1.54,1.75,4.13,4.69,4.5,5.31S38.46,23,37.89,23.72Z'
      />
      <circle className='port-center' cx='37.15' cy='23.21' r='0.41' />
      <circle className='port-circle' cx='37.15' cy='23.21' r='0.68' />
      <circle className='port-circle' cx='37.15' cy='23.21' r='0.94' />
    </g>
  );
};

export default Fos;
