import React from 'react';
import {PortType} from '..';

const Ashdod: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='other-subregion'
        d='M98.77,57.19l.22.12a12.06,12.06,0,0,1,.68-3.05,2.77,2.77,0,0,0,.26-1,2.7,2.7,0,0,1-.78-1.34c0-.19.13-1.39.45-1.52a1.9,1.9,0,0,1,.64,0c.05-.37.12-.91.17-1.4l-1.06-.45a4,4,0,0,0-.34,1,13.18,13.18,0,0,1-1.66,3.74L98.86,57Z'
      />
      <circle className='port-center' cx='98.39' cy='51.44' r='0.41' />
      <circle className='port-circle' cx='98.39' cy='51.44' r='0.68' />
      <circle className='port-circle' cx='98.39' cy='51.44' r='0.94' />
    </g>
  );
};

export default Ashdod;
