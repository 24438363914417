import DateFilter from '@erkport/components/AppPageFilter/DateFilter';
import VesselLoadUnitFilter from '@erkport/components/AppPageFilter/LoadUnitFilter';
import VesselFilter from '@erkport/components/AppPageFilter/VesselFilter';
import AppRowContainer from '@erkport/components/AppRowContainer';
import moment from '@erkport/services/moment';
import type {Moment} from 'moment';
import React, {useState} from 'react';
import VesselStoryPage from './page';
import {useQueries} from 'react-query';
import {getVessels} from '@erkport/services/erkport';
import Loading from '@erkport/components/AppLoadable/Loading';
import PageWrapper from '@erkport/components/PageWrapper';

const VesselStory = () => {
  const [date, setDate] = useState<Moment>(moment());
  const [vessel, setVessel] = useState<string>('');
  const [loadUnit, setLoadUnit] = useState<string>('m2');

  const queries = useQueries([{queryKey: 'vessels', queryFn: getVessels}]);

  if (queries.some((x) => x.isLoading)) {
    return <Loading />;
  }
  const vessels = queries[0].data;

  return (
    <>
      <PageWrapper
        vesselsProvider={getVessels}
        hasDate
        dateConfig={{allowClear: false, labelKey: 'common.finishingDate'}}
        hasLoadUnit
      >
        {(props) => <VesselStoryPage {...props} />}
      </PageWrapper>
    </>
  );
};

export default VesselStory;
