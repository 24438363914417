import React from 'react';
import {StyledInfoCard, StyledInfoCardContent} from './index.styled';

type InfoCardType = {
  iconPath: string;
  count: number | string;
  details: string;
};

const InfoCard: React.FC<InfoCardType> = ({iconPath, count, details}) => {
  return (
    <StyledInfoCard heightFull>
      <StyledInfoCardContent>
        <h3>{count}</h3>
        <h2>{details}</h2>
      </StyledInfoCardContent>
    </StyledInfoCard>
  );
};

export default InfoCard;
