import React, {useEffect, useState} from 'react';
import PortsEurope from './ports_europe.svg';
import {Row, Col} from 'antd';
import {ReactComponent as Map} from './ports_world_cropped.svg';
import YearFilter from '@erkport/components/AppPageFilter/YearFilter';
import MonthFilter from '@erkport/components/AppPageFilter/MonthFilter';
import AppCard from '@erkport/components/AppCard';
import AppRowContainer from '@erkport/components/AppRowContainer';
import ImportExportChart from '../components/importExportChart';
import {getLastMonths, getLastWeeks} from '@erkport/helpers/DateHelper';
import ClusterDetail from '../components/clusterDetail';
import {PageWithFilterProps} from '@erkport/components/PageWrapper';
import Loading from '@erkport/components/AppLoadable/Loading';
import {getDataGroup2} from '@erkport/services/erkport';
import {useIntl} from 'react-intl';

const importExportMonthlyData = getLastMonths(2024, 5, 12).map((x) => ({
  x: x.month,
  import: 5 + Math.random() * 10,
  export: 5 + Math.random() * 10,
}));

const importExportWeeklyData = getLastWeeks(2024, 5, 12).map((x) => ({
  x: x.week,
  import: 5 + Math.random() * 10,
  export: 5 + Math.random() * 10,
}));

const importExportData = (
  data: any[],
  year: number,
  month?: number,
  week?: number,
) => {
  const monthData = data?.filter((x) =>
    Number(x.year) == year && month
      ? Number(x.month) == month
      : true && week
      ? Number(x.week) == week
      : true,
  );

  const exportData = monthData?.reduce(
    (acc, cur) => (acc.totalSalesEur || 0) + cur.totalSalesEur,
    0,
  );

  const importData = monthData?.reduce(
    (acc, cur) => (acc.totalSalesEur || 0) + cur.totalSalesEur,
    0,
  );

  return {
    import: importData,
    export: exportData,
    x: month,
  };
};

interface WorldMapPageProps extends PageWithFilterProps {}

const WorldMapPage = ({year, month}: WorldMapPageProps) => {
  const [data, setData] = useState(null);

  const {messages} = useIntl();

  useEffect(() => {
    getDataGroup2().then((x) => setData(x));
  }, []);

  if (!data) return <Loading />;

  const importExportMonthlyData = getLastMonths(year, month, 12).map((x) =>
    importExportData(data, x.year, x.month, null),
  );

  const importExportWeeklyData = getLastWeeks(year, month * 4, 12).map((x) =>
    importExportData(data, x.year, null, x.week),
  );

  return (
    <>
      <AppRowContainer>
        <Col xs={24}>
          <AppCard>
            <Map />
          </AppCard>
        </Col>
        <Col xs={24}>
          <div style={{position: 'absolute', bottom: 0, width: '99%'}}>
            <AppRowContainer>
              <Col xs={12}>
                <AppCard
                  title={messages['charts.last12Months'] as string}
                  bordered
                  noPadding
                >
                  <ImportExportChart
                    containerName='world-monthly-import-export-chart'
                    data={importExportMonthlyData}
                    height={250}
                  />
                </AppCard>
              </Col>
              <Col xs={12}>
                <AppCard
                  title={messages['charts.last12Weeks'] as string}
                  bordered
                  noPadding
                >
                  <ImportExportChart
                    containerName='world-weekly-import-export-chart'
                    data={importExportWeeklyData}
                    height={250}
                  />
                </AppCard>
              </Col>
            </AppRowContainer>
          </div>
        </Col>
      </AppRowContainer>
    </>
  );
};

export default WorldMapPage;
