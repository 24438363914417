import React from 'react';
import {PortType} from '..';

const Mostaganem: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='north-african-subregion'
        d='M24.05,51.41a.91.91,0,0,0,.09-.61c-.06-.34-.41-1.37-.82-1.44s-.14-1.1-.14-1.92a3.46,3.46,0,0,0-.61-2.2,3.81,3.81,0,0,1-.35-.64,1,1,0,0,0,.79-.36,5.45,5.45,0,0,1,3.3-1.71c1,0,1.65-1.68,3.27-1.74.82,0,2.7-.29,4.42-.46A12.42,12.42,0,0,0,33.92,44a16,16,0,0,0,.6,2.71A65.86,65.86,0,0,0,24.05,51.41Z'
      />
      <circle className='port-center' cx='26.77' cy='42.29' r='0.41' />
      <circle className='port-circle' cx='26.77' cy='42.29' r='0.68' />
      <circle className='port-circle' cx='26.77' cy='42.29' r='0.94' />
    </g>
  );
};

export default Mostaganem;
