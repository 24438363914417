import React from 'react';
import {PortType} from '..';

const Antwerp: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='continental-subregion'
        d='M32.2,3.3c0,.33.56,1.12.75.79s.65.42,1.07.66,1.26.51,1.31.79.18.89.6.7.94-.84.94-.47.14,1.08.6,1,.61.8,1.08.7l.33,0c-.05-1,.32-1.55.74-1.78.1-.19.38-.38.42-.61s-.62-.94-.56-1.31c0,0,0-.05,0-.07a4.25,4.25,0,0,1-.46-.4c-.57-.57-1.14-.66-1.52-1A1.06,1.06,0,0,0,36.08,2a3.59,3.59,0,0,1-2,.66,2.51,2.51,0,0,1-.75-.26,4.74,4.74,0,0,1-1.4.6C32,3.15,32.17,3.08,32.2,3.3Z'
      />
      <circle className='port-center' cx='33.96' cy='2.29' r='0.41' />
      <circle className='port-circle' cx='33.96' cy='2.29' r='0.68' />
      <circle className='port-circle' cx='33.96' cy='2.29' r='0.94' />
    </g>
  );
};

export default Antwerp;
