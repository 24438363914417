import trMessages from './tr_TR.json';
import trTR from 'antd/lib/locale/tr_TR';

const TrLang = {
  messages: {
    ...trMessages,
  },
  antLocale: trTR,
  locale: 'tr-TR',
};
export default TrLang;
