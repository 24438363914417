import {isNull, isNullOrEmpty} from './Validator';

const CURRENCY_CODES = {
  TRY: 'tr-TR',
  USD: 'en-EN',
  EUR: 'de-DE',
};

const _formatNumber = (number, currency, digits, unit) => {
  if (!number) {
    number = '0';
  }
  const fractionDigits = isNull(digits) ? 2 : digits;

  const formatter = isNullOrEmpty(currency)
    ? new Intl.NumberFormat('tr-TR', {
        style: 'decimal',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      })
    : new Intl.NumberFormat(CURRENCY_CODES[currency], {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      });

  if (unit === 'K') {
    number = number / Math.pow(10, 3);
  }
  if (unit === 'M') {
    number = number / Math.pow(10, 6);
  }
  let output = formatter.format(number);

  if (!isNullOrEmpty(unit)) {
    output = `${output} ${unit}`;
  }

  return output;
};

export const roundNumber = (number, digits) => {
  const factor = Math.pow(10, digits || 0);
  return Math.round(Number(number || 0) * factor) / factor;
};

export const formatNumberMK = (numberStr, digits?) =>
  Math.abs(Number(numberStr || 0)) >= 1000000
    ? formatNumberM(numberStr, digits)
    : formatNumberK(numberStr, digits);
export const formatNumberM = (numberStr, digits) =>
  _formatNumber(numberStr, null, digits || 0, 'M');
export const formatNumberK = (numberStr, digits) =>
  Math.abs(Number(numberStr || 0)) >= 1000
    ? _formatNumber(numberStr, null, digits || 0, 'K')
    : _formatNumber(numberStr, null, digits || 0, null);
export const formatNumberZ = (numberStr) =>
  _formatNumber(numberStr, null, 0, null);
export const formatNumber = (numberStr, digits?) =>
  _formatNumber(numberStr, null, digits || 0, null);
export const formatCur = (numberStr, currency, digits) =>
  _formatNumber(numberStr, currency, digits || 0, null);
