import {formatNumberM, formatNumberMK} from '@erkport/helpers/NumberFormatter';

export const drawChart = (container, data, legends) => {
  document.getElementById(container).innerHTML = '';

  var firstSeriesData = data.current.map((x) => ({
    x: x.x || x.month,
    value: x.totalSalesEur,
  }));

  var secondSeriesData = data.current.map((x) => ({
    x: x.x || x.month,
    value: x.totalProfitEur,
  }));

  var thirdSeriesData = data.prev.map((x) => ({
    x: x.x || x.month,
    value: x.totalSalesEur,
  }));

  var fourthSeriesData = data.prev.map((x) => ({
    x: x.x || x.month,
    value: x.totalProfitEur,
  }));

  const maxData = Math.floor(
    Math.max(
      ...firstSeriesData.map((x) => x.value),
      ...secondSeriesData.map((x) => x.value),
      ...thirdSeriesData.map((x) => x.value),
      ...fourthSeriesData.map((x) => x.value),
    ),
  );
  // create line chart
  var chart = window.anychart.line();

  // turn on chart animation
  chart.animation(true);

  // turn on the crosshair
  chart.crosshair().enabled(false);

  // set tooltip mode to point
  chart.tooltip().positionMode('point');

  // set yAxis title
  chart.yAxis().title('');
  chart
    .yAxis()
    .labels()
    .format(function () {
      return formatNumberMK(this.value);
    });
  chart.xAxis().title('');
  chart.yScale().maximum(Math.ceil(maxData * 1.5));

  var scale = window.anychart.scales.linear();
  scale.minimum(0).ticks({interval: 20});

  // #081E58
  var firstSeries = chart.column(firstSeriesData);
  firstSeries.name(legends[0]);
  firstSeries.fill('rgb(108, 180, 238)').stroke('rgb(19, 34, 87)');
  firstSeries
    .labels()
    .format(function () {
      return formatNumberM(this.value, 2);
    })
    .enabled(true)
    .fontColor('rgb(19, 34, 87)')
    .position('top');

  var secondSeries = chart.column(secondSeriesData);
  secondSeries.name(legends[1]);
  secondSeries.fill('rgb(19, 34, 87)').stroke('rgb(19, 34, 87)');
  secondSeries
    .labels()
    .format(function () {
      return formatNumberM(this.value, 2);
    })
    .enabled(true)
    .fontColor('white')
    .position('center');

  var thirdSeries = chart.line(thirdSeriesData);
  thirdSeries.hovered().markers().enabled(false);
  thirdSeries.selected().markers().enabled(false);
  thirdSeries.name(legends[2]);

  var fourthSeries = chart.line(fourthSeriesData);
  fourthSeries.hovered().markers().enabled(false);
  fourthSeries.selected().markers().enabled(false);
  fourthSeries.name(legends[3]);

  chart.barsPadding(-1);
  // turn the legend on
  chart.legend().enabled(true);

  chart.credits().enabled(false);

  // set container id for the chart
  chart.container(container);
  // initiate chart drawing

  chart.draw();
};
