import React, {ReactNode} from 'react';

type AppFlexItemProps = {
  children: ReactNode;
};

const AppFlexItem: React.FC<AppFlexItemProps> = ({children}) => {
  return (
    <div
      style={{
        flex: '1',
        margin: '0 0 .5rem .5rem',
      }}
    >
      {children}
    </div>
  );
};

export default AppFlexItem;
