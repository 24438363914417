import React from 'react';
import {PortType} from '..';

const Tanger: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='north-african-subregion'
        d='M23.66,51.63c-.41,0-3.08-.28-3.08.13s-.62.76-1.24.76h-.17a1,1,0,0,0-.3,0h0A4.87,4.87,0,0,0,15.79,50a9.49,9.49,0,0,1-4.37-2.45l.19-.17a1.86,1.86,0,0,0,.33-.36c.62-.9,1.71-4,2.3-4.29s1.15,0,2,.9,2.49.34,3.58.37c.78,0,1.69.59,2.44.58a3.81,3.81,0,0,0,.35.64,3.46,3.46,0,0,1,.61,2.2c0,.82-.27,1.85.14,1.92s.76,1.1.82,1.44S24.08,51.63,23.66,51.63Z'
      />
      <circle className='port-center' cx='14.7' cy='43.12' r='0.41' />
      <circle className='port-circle' cx='14.7' cy='43.12' r='0.68' />
      <circle className='port-circle' cx='14.7' cy='43.12' r='0.94' />
    </g>
  );
};

export default Tanger;
