import React from 'react';
import {PortType} from '..';

const Setubal: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='continental-subregion'
        d='M11.63,37.46c-.84.15-.84.83-.84,1.29a1.62,1.62,0,0,0,.31.68,11.54,11.54,0,0,1-1.68.33c-.88,0-1.62.16-1.62-.4s.56-2.93-.28-3.58-1.62-.9-.81-1.84a5.43,5.43,0,0,0,.77-1.52A8.32,8.32,0,0,1,9,32.57a8.33,8.33,0,0,0,2.88-.42c-.16.52.23,1.14-.2,1.14s-1.14.23-.54.84,1.06,1.13.61,1.13-.76.91-.53,1.14S12.46,37.31,11.63,37.46Z'
      />
      <circle className='port-center' cx='7.44' cy='36.69' r='0.41' />
      <circle className='port-circle' cx='7.44' cy='36.69' r='0.68' />
      <circle className='port-circle' cx='7.44' cy='36.69' r='0.94' />
    </g>
  );
};

export default Setubal;
