import React from 'react';
import {PortType} from '..';

const Tarragona: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='baleric-subregion'
        d='M30.12,29.23c-1,.14-1.75.39-1.75,1s-.91.78-1.75,2.05a.75.75,0,0,0-.07.12,1.66,1.66,0,0,0-.13.27,2.84,2.84,0,0,0-.22-.35,3.58,3.58,0,0,0-2.66-1.49,34.91,34.91,0,0,1-3.74-.49c0-.08.09-.15.14-.23.59-.92,1.18-1.83,1.74-2.66a10.7,10.7,0,0,0,2.84.54C28.2,28.28,29.6,28.82,30.12,29.23Z'
      />
      <circle className='port-center' cx='29.02' cy='30.03' r='0.41' />
      <circle className='port-circle' cx='29.02' cy='30.03' r='0.68' />
      <circle className='port-circle' cx='29.02' cy='30.03' r='0.94' />
    </g>
  );
};

export default Tarragona;
