import React, {useState, useEffect} from 'react';
import Loadable from '@erkport/components/AppLoadable';

type ChartContainerType = {
  containerName: string;
  data: any;
  height?: any;
  drawChart: any;
  style?: any;
};

const ChartContainer: React.FC<ChartContainerType> = ({
  containerName,
  data,
  drawChart,
  height = 300,
  style = {},
}) => {
  const [isDrawed, setDrawed] = useState(false);

  useEffect(() => {
    const chartContainer = document.getElementById(containerName);

    if (!!chartContainer && data) {
      drawChart(containerName, data);
      setDrawed(true);
    }
  }, [data]);

  return (
    <Loadable isLoading={!isDrawed}>
      <div id={containerName} style={{height, ...style}}></div>
    </Loadable>
  );
};

export default ChartContainer;
