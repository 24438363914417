import React from 'react';
import {PortType} from '..';

const Sete: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='baleric-subregion'
        d='M35.1,23.61h0c-1,.16-2.12.87-1.74,2.09a2.94,2.94,0,0,1,.12.54,13.5,13.5,0,0,1-2.16-.07c-.68-.15-2.12-.22-2.5-.6s-1-.68-1-.23-1.06.53-1.67.15-1.59-.68-2-1L24,24.46c1.33-1.37,3.46-3.36,5.77-5.43a9.68,9.68,0,0,0,2.73,1.74A3.66,3.66,0,0,1,35.1,23.61Z'
      />
      <circle className='port-center' cx='34.15' cy='24.21' r='0.41' />
      <circle className='port-circle' cx='34.15' cy='24.21' r='0.68' />
      <circle className='port-circle' cx='34.15' cy='24.21' r='0.94' />
    </g>
  );
};

export default Sete;
