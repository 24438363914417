import styled from 'styled-components';
import {Layout} from 'antd';

const {Header} = Layout;

export const StyledDropdownWrapper = styled.div`
  & .dropdown-wrapper {
    .ant-dropdown-menu {
      padding: 0;

      .ant-dropdown-menu-item {
        padding: 0;
      }
    }
  }

  & .ant-dropdown-trigger {
    text-transform: none;
  }
  & .ant-dropdown-link-mobile {
    font-weight: ${({theme}) => theme.font.weight.medium};
    text-transform: uppercase;
    margin-top: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    background-color: ${({theme}) => theme.palette.background.default};
    color: ${({theme}) => theme.palette.text.primary};
    padding: 9px;
    border-radius: ${({theme}) => theme.sizes.borderRadius.circle};
    border: 1px solid transparent;
  }
`;

export const StyledDrawerLayoutHeader = styled(Header)`
  padding-left: 20px;
  padding-right: 20px;
  color: ${({theme}) => theme.palette.text.primary}!important;
  background-color: ${({theme}) => theme.palette.background.paper}!important;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderColor}!important;
  height: 56px;
  line-height: 1;
  transition: all 0.1s linear;
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;

  @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
    padding-left: 30px;
    padding-right: 30px;
    height: 71px !important;
  }

  & .trigger {
    font-size: 20px;
    margin-right: 10px;
    padding: 5.5px;
    color: ${({theme}) => theme.palette.text.primary};

    & svg {
      display: block;
    }

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 20px;
    }
  }
`;

export const StyledDrawerLayoutHeaderDesk = styled.div`
  display: flex;
  position: relative;
  margin-left: auto;

  @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
    display: flex;
    align-items: center;
  }
`;
export const StyledDrawerLayoutHeaderMobile = styled.div`
  display: block;

  @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
    display: none;
  }

  & .ant-dropdown-link-mobile {
    font-size: 24px;
    color: ${({theme}) => theme.palette.text.primary};

    & svg {
      display: block;
    }
  }
`;
