import React from 'react';
import {PortType} from '..';

const Livorno: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='baleric-subregion'
        d='M53.87,25.52a1.88,1.88,0,0,0-.57,1.63,9.43,9.43,0,0,1,0,1.32l-.24-.2a12.73,12.73,0,0,0-2.24-2,4.1,4.1,0,0,1-2-2.55c-.09-.53-.22-.6-.65-1.25a3.23,3.23,0,0,0-3-1.25c-1.09.32-.81.91-1.46,1.25a4.71,4.71,0,0,1-1,.29,3,3,0,0,1,.23-.71c.14-.37-.51-.28-1-.33a.64.64,0,0,1-.47-1c.33-.51-.27-.56-.6-1.07s.14-.37.42-.51.65-.7.42-.84a.73.73,0,0,1-.29-.8,1.8,1.8,0,0,1,0-.19c.64-.06,1.52-.13,1.79-.13.44,0,.88-1.37,1.06-1.5s.69,1,1.19,1.44.56-.38.56-.75.43-.5.74-.44a2,2,0,0,0,.67.06l.09,0h0a.23.23,0,0,0,.12-.06.23.23,0,0,0,.07-.07v0a33.28,33.28,0,0,0,1.47,3,1.82,1.82,0,0,0,.15.27,37.42,37.42,0,0,0,4.33,6.2c0,.07.11.13.17.2Z'
      />
      <circle className='port-center' cx='48.09' cy='23.23' r='0.41' />
      <circle className='port-circle' cx='48.09' cy='23.23' r='0.68' />
      <circle className='port-circle' cx='48.09' cy='23.23' r='0.94' />
    </g>
  );
};

export default Livorno;
