import React from 'react';
import {PortType} from '..';

const Istanbul: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='other-subregion'
        d='M85,28.18a2.06,2.06,0,0,1-.52-1.09.86.86,0,0,1-.85.16,4.18,4.18,0,0,0-2.15,0c-.12.05-.07.29,0,.57a3.26,3.26,0,0,1,.18.83c0,.51-.61.65-.61,1s-.22.43-.49.72A2.24,2.24,0,0,0,81.8,31c.87,0,1.21-.94,2-1.22s2.43.09,2.83-.28S85.53,28.62,85,28.18Zm33.8,9.58c-.33-.08.17-1.91,0-2.24s-.58-1.74-.08-1.74a.17.17,0,0,0,.13-.05.22.22,0,0,0,0-.08,1.46,1.46,0,0,0,0-.39c0-.07,0-.13,0-.2a.8.8,0,0,1,0-.18.16.16,0,0,1,0-.07l.06-.05a.08.08,0,0,1,.08,0l.1,0h0a2.07,2.07,0,0,0-.39-.76c-.31-.25-1.24.25-1.61-.69a2.22,2.22,0,0,1-.16-.54.75.75,0,0,1,0-.2,1.87,1.87,0,0,0-.16-.71,1.08,1.08,0,0,0-.11-.21h0a.8.8,0,0,0-.09-.14c-.56-.75-.81-1.49-1.5-1.18a6.5,6.5,0,0,1-2.2.06c-1,.92-3.22,1.56-3.9,1.31-.84-.31-2.45.09-3.67.09s-1.56-.87-2.49-.81-.93-.84-1.87-.84-.21-.47-3.14-.84a8.75,8.75,0,0,0-6.38,2c-.93.81-4.29,0-4.6.25s-.14.46-.26.82a1.22,1.22,0,0,1-.1.24c1.45.34,2.52.71,2.54.92,0,0,0,0-.07.08a10.43,10.43,0,0,1-4.41.92c-.34-.1-.77-.68-1.08-1.15h-.31a5.47,5.47,0,0,0-1.79.17c-.84.37-1.15,1.62-.44,1.56a1.66,1.66,0,0,1,.57,0,.71.71,0,0,1,.31.19,5.53,5.53,0,0,1,2.62,0c.37.13.71.23,1,.33a3.8,3.8,0,0,1,1.83.86,5.63,5.63,0,0,1,.8,3A.76.76,0,0,1,88,38l-.28.23c-.21.19-.21.19-1.75.81l-1.4.56a.17.17,0,0,1,0,.11.28.28,0,0,0,0,.13c.08.27.46.35,1,.18s.9.72,1.31,1.34,2.7.18,2.7-.5.69-.87,1.71-.78,2.37,1.49,2.87,1.68,1.52-.37,2.18-.37,1.58-1,2-1.25a.18.18,0,0,1,.16,0,.6.6,0,0,1,.28.1c0-.07,0-.14,0-.21a1.22,1.22,0,0,1,0-.76c.31-.54,1.45-2.27,3.17-1.91a2.66,2.66,0,0,1,1.73.86,1.25,1.25,0,0,1,.07,1c-.11.43-.29,1-.43,1.46a.94.94,0,0,0,.23.1,2,2,0,0,0,1.54-.2c.91-.42,1-.67,1.33-.25s1.08.08,2.41.08,1.58-.91,2.83-.83a6.18,6.18,0,0,0,2.32-.33l.79.24c.33-.3.3-.64.94-.72,1-.12,1.37.63,1.87.38s1.67,1,2.09.65a.36.36,0,0,0,.07-.1.38.38,0,0,0,0-.16s0,0,0,0A2.93,2.93,0,0,0,118.81,37.76Z'
      />
      <circle className='port-center' cx='86.39' cy='29.29' r='0.41' />
      <circle className='port-circle' cx='86.39' cy='29.29' r='0.68' />
      <circle className='port-circle' cx='86.39' cy='29.29' r='0.94' />
    </g>
  );
};

export default Istanbul;
