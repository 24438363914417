import React, {useEffect, useState} from 'react';
import PortsEurope from './ports_europe.svg';
import {Row, Col} from 'antd';
import Map from './map';
import YearFilter from '@erkport/components/AppPageFilter/YearFilter';
import MonthFilter from '@erkport/components/AppPageFilter/MonthFilter';
import AppCard from '@erkport/components/AppCard';
import AppRowContainer from '@erkport/components/AppRowContainer';
import ImportExportChart from '../components/importExportChart';
import {getLastMonths, getLastWeeks} from '@erkport/helpers/DateHelper';
import ClusterDetail from '../components/clusterDetail';
import {PageWithFilterProps} from '@erkport/components/PageWrapper';
import Loading from '@erkport/components/AppLoadable/Loading';
import {getDataGroup2, getPorts} from '@erkport/services/erkport';
import {useIntl} from 'react-intl';
import {formatNumber, formatNumberMK} from '@erkport/helpers/NumberFormatter';

const importExportData = (
  data: any[],
  cluster: string,
  year: number,
  month?: number,
  week?: number,
) => {
  const filteredData = data?.filter(
    (x) =>
      Number(x.year) == year &&
      (Number(x.month) == month || Number(x.week) == week),
  );

  const exportData = filteredData
    ?.filter((x) => x.fromCluster?.toLowerCase() == cluster)
    ?.reduce((acc, cur) => (acc.totalSalesEur || 0) + cur.totalSalesEur, 0);

  const importData = filteredData
    ?.filter((x) => x.toCluster?.toLowerCase() == cluster)
    ?.reduce((acc, cur) => (acc.totalSalesEur || 0) + cur.totalSalesEur, 0);

  return {
    import: importData,
    export: exportData,
    x: month || week,
    cluster,
  };
};

interface EuropeMapPageProps extends PageWithFilterProps {}

const EuropeMapPage = ({year, month}: EuropeMapPageProps) => {
  const [data, setData] = useState(null);
  const [cluster, setCluster] = useState(null);

  const {messages} = useIntl();

  useEffect(() => {
    Promise.all([getDataGroup2(), getPorts()]).then(([x, y]) =>
      setData({data: x, ports: y}),
    );
  }, []);

  if (!data) return <Loading />;

  const clusterData = data?.data?.filter(
    (x) =>
      x.toCluster?.toLowerCase() == cluster ||
      x.fromCluster?.toLowerCase() == cluster,
  );

  const clusterPorts = data?.ports?.filter(
    (x) => x.cluster.toLowerCase() == cluster,
  );

  const importExportMonthlyData = cluster
    ? getLastMonths(year, month, 12).map((x) =>
        importExportData(clusterData, cluster, x.year, x.month, null),
      )
    : getLastMonths(year, month, 12).map((x) => ({
        x: x.month,
        import: 0,
        export: 0,
      }));

  const importExportWeeklyData = cluster
    ? getLastWeeks(year, month * 4, 12).map((x) =>
        importExportData(clusterData, cluster, x.year, null, x.week),
      )
    : getLastWeeks(year, month * 4, 12).map((x) => ({
        x: x.week,
        import: 0,
        export: 0,
      }));

  return (
    <>
      <AppRowContainer>
        <Col xs={22}>
          <AppCard>
            <div style={{position: 'absolute', top: 0, right: 0, width: '32%'}}>
              <AppCard
                title={messages['charts.last12Months'] as string}
                extra={
                  <span style={{fontWeight: 500, fontSize: 16}}>
                    {cluster?.toUpperCase()}
                  </span>
                }
                bordered
                noPadding
              >
                <ImportExportChart
                  containerName='europe-monthly-import-export-chart'
                  data={importExportMonthlyData}
                  height={145}
                />
              </AppCard>
              <AppCard
                title={messages['charts.last12Weeks'] as string}
                bordered
                noPadding
              >
                <ImportExportChart
                  containerName='europe-weekly-import-export-chart'
                  data={importExportWeeklyData}
                  height={145}
                />
              </AppCard>
            </div>
            <Map selectedCluster={cluster} onClusterSelected={setCluster} />
          </AppCard>
        </Col>
      </AppRowContainer>
      <ClusterDetail ports={clusterPorts} clusterData={clusterData} />
    </>
  );
};

export default EuropeMapPage;
