import styled from 'styled-components';
import AppCard from '@erkport/components/AppCard';

export const StyledInfoCard = styled(AppCard)`
  & .ant-card-body {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const StyledInfoCardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;

  & h3 {
    font-weight: ${({theme}) => theme.font.weight.medium};
    font-size: 28px;
    color: ${({theme}) => theme.palette.text.primary};
    margin-bottom: 0;
  }

  & h2 {
    font-weight: ${({theme}) => theme.font.weight.medium};
    font-size: 28px;
    color: ${({theme}) => theme.palette.text.secondary};
    margin-bottom: 0;
  }
`;
