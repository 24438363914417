import React from 'react';
import InfoCard from './infoCard';

import {Card, Col} from 'antd';
import {isNullOrEmpty} from '@erkport/helpers/Validator';
import AppRowContainer from '@erkport/components/AppRowContainer';
import AppCard from '@erkport/components/AppCard';
import {
  FallOutlined,
  ReloadOutlined,
  RiseOutlined,
  SlidersOutlined,
} from '@ant-design/icons';
import {PiMoney} from 'react-icons/pi';

import {useIntl} from 'react-intl';

const getFullData = (_data: any[], source: string) => {
  return Array.from(
    new Set(_data.flatMap((x) => x.data).map((x) => x[source])),
  );
};

const getIncreasingData = (_data: any[], source: string, expression: any) => {
  const controlData = _data.slice(-2)[0]?.data || [];

  const totalData: any[] = _data
    .slice(-5, -2)
    .map((x) => x.data)
    .flatMap((x) => x)
    .reduce(
      (total, current) => ({
        ...total,
        [current[source]]:
          Number(current?.totalSalesEur || 0) + (total[current[source]] || 0),
      }),
      0,
    );

  const filteredData = Object.entries(totalData)
    .filter(([customer, total]) =>
      expression(
        controlData.filter((x) => x[source] == customer)[0]?.totalSalesEur || 0,
        total / 3,
      ),
    )
    .map(([customer]) => customer);

  return filteredData;
};

const getKeyCustomerData = (_data: any[], source: string, expression: any) => {
  const controlData = _data
    .map((x) => x.data)
    .flatMap((x) => x)
    .reduce(
      (total, current) => ({
        ...total,
        [current[source]]:
          Number(current?.totalSalesEur || 0) + (total[current[source]] || 0),
      }),
      {},
    );

  return Object.entries(controlData)
    .filter(([_, total]) => expression(total))
    .map(([customer, _]) => customer);
};

type CardContainerType = {
  data: any[];
  selectedCardType: string;
  setSelectedCard: any;
  source: string;
};

const CUSTOMER_TREND_CARDS = {
  all: 'customer.trend.cards.all',
  increasing: 'customer.trend.cards.increasing',
  decreasing: 'customer.trend.cards.decreasing',
  domesticSales: 'customer.trend.cards.domesticSales',
  fieldSales: 'customer.trend.cards.fieldSales',
  keyCustomer: 'customer.trend.cards.keyCustomer',
  pcc: 'customer.trend.cards.pcc',
  pctc: 'customer.trend.cards.pctc',
};

const CardContainer: React.FC<CardContainerType> = ({
  data,
  selectedCardType,
  setSelectedCard,
  source,
}) => {
  const {messages} = useIntl();
  return (
    <AppRowContainer>
      <Col xs={8} md={3}>
        <InfoCard
          data={getFullData(data, source)}
          onClick={(_data) => setSelectedCard({type: 'all', data: _data})}
          selected={selectedCardType == 'all'}
          title={messages[CUSTOMER_TREND_CARDS['all']] as string}
          icon={<ReloadOutlined />}
        />
      </Col>
      <Col xs={8} md={3}>
        <InfoCard
          data={getIncreasingData(data, source, (x, y) => x >= y)}
          title={messages[CUSTOMER_TREND_CARDS['increasing']] as string}
          onClick={(_data) =>
            setSelectedCard({type: 'increasing', data: _data})
          }
          selected={selectedCardType == 'increasing'}
          icon={<RiseOutlined />}
        />
      </Col>
      <Col xs={8} md={3}>
        <InfoCard
          data={getIncreasingData(data, source, (x, y) => x < y)}
          title={messages[CUSTOMER_TREND_CARDS['decreasing']] as string}
          onClick={(_data) =>
            setSelectedCard({type: 'decreasing', data: _data})
          }
          selected={selectedCardType == 'decreasing'}
          icon={<FallOutlined />}
        />
      </Col>
      <Col xs={5} md={3}>
        <InfoCard
          data={getKeyCustomerData(data, source, (x) => x < 75000)}
          title={messages[CUSTOMER_TREND_CARDS['domesticSales']] as string}
          onClick={(_data) =>
            setSelectedCard({type: 'domesticSales', data: _data})
          }
          selected={selectedCardType == 'domesticSales'}
          icon={<PiMoney />}
        />
      </Col>
      <Col xs={5} md={3}>
        <InfoCard
          data={getKeyCustomerData(
            data,
            source,
            (x) => x >= 75000 && x < 1500000,
          )}
          title={messages[CUSTOMER_TREND_CARDS['fieldSales']] as string}
          onClick={(_data) =>
            setSelectedCard({type: 'fieldSales', data: _data})
          }
          selected={selectedCardType == 'fieldSales'}
          icon={<PiMoney />}
        />
      </Col>
      <Col xs={5} md={3}>
        <InfoCard
          data={getKeyCustomerData(data, source, (x) => x >= 1500000)}
          title={messages[CUSTOMER_TREND_CARDS['keyCustomer']] as string}
          onClick={(_data) =>
            setSelectedCard({type: 'keyCustomer', data: _data})
          }
          selected={selectedCardType == 'keyCustomer'}
          icon={<PiMoney />}
        />
      </Col>
      <Col xs={5} md={3}>
        <InfoCard
          data={getFullData(data, source)}
          title={messages[CUSTOMER_TREND_CARDS['pcc']] as string}
          onClick={(_data) => setSelectedCard({type: 'pcc', data: _data})}
          selected={selectedCardType == 'pcc'}
          icon={<SlidersOutlined />}
        />
      </Col>
      <Col xs={4} md={3}>
        <InfoCard
          data={getFullData(data, source)}
          title={messages[CUSTOMER_TREND_CARDS['pctc']] as string}
          onClick={(_data) => setSelectedCard({type: 'pctc', data: _data})}
          selected={selectedCardType == 'pctc'}
          icon={<SlidersOutlined />}
        />
      </Col>
    </AppRowContainer>
  );
};

export default CardContainer;
