import React from 'react';
import {PortType} from '..';

const Vigo: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='continental-subregion'
        d='M11.85,29.88c0,.45.66,1.42.17,1.95h0s0,0,0,0a.57.57,0,0,0-.17.3A8.33,8.33,0,0,1,9,32.57a8.32,8.32,0,0,0-1.47-.15l.08-.24c.44-1.31.71-2.87.46-3.28A2.68,2.68,0,0,1,8,27.47a.79.79,0,0,0,.56-.24c.38-.45.76-.38.76.08s.83.3,1.28.3.31.38.84,0,1.28-.22,1.28.23,1.14.23.53.83S11.85,29.43,11.85,29.88Z'
      />
      <circle className='port-center' cx='8.22' cy='28.7' r='0.41' />
      <circle className='port-circle' cx='8.22' cy='28.7' r='0.68' />
      <circle className='port-circle' cx='8.22' cy='28.7' r='0.94' />
    </g>
  );
};

export default Vigo;
