import React from 'react';
import {PortType} from '..';

const DjenDjen: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='north-african-subregion'
        d='M43.9,45.42c-.46.23-.81,1.2-1.25,1.36-2.74-.26-4.83-.94-7.57-.22l-.32.09-.24.07a16,16,0,0,1-.6-2.71A12.42,12.42,0,0,1,34,40.33l.26,0c1.58-.16,3-.24,3.23,0,.56.53,2.24-.56,2.92-.59a13.09,13.09,0,0,1,2.15-.22,7.23,7.23,0,0,0,2,.39c-.19,1.24-.35,2.79,0,3.28A1.84,1.84,0,0,1,43.9,45.42Z'
      />
      <circle className='port-center' cx='40.77' cy='39.29' r='0.41' />
      <circle className='port-circle' cx='40.77' cy='39.29' r='0.68' />
      <circle className='port-circle' cx='40.77' cy='39.29' r='0.94' />
    </g>
  );
};

export default DjenDjen;
