import ChartContainer from '@erkport/components/AppChartContainer';
import React from 'react';
import {useIntl} from 'react-intl';
import {drawChart} from './chart';

type ParetoChartType = {
  containerName: string;
  data: any[];
  height?: number;
};

const ParetoChart: React.FC<ParetoChartType> = ({
  containerName,
  data,
  height,
}) => {
  const {messages} = useIntl();
  return (
    <ChartContainer
      containerName={containerName}
      drawChart={(_containerName, _data) =>
        drawChart(
          _containerName,
          _data,
          messages['charts.cumulativePercentage'],
          messages['charts.giro'],
        )
      }
      data={data}
      height={height}
    />
  );
};

export default ParetoChart;
