import React from 'react';
import {PortType} from '..';

const Pireaus: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='other-subregion'
        d='M79.75,43.53c-.5-.28-2.74.18-3.33-.38s-1.8.45-1.28.53a15.71,15.71,0,0,0,3.08.81c1.21,0,2.86-.56,2.83-.87S80.24,43.81,79.75,43.53ZM81.5,27.82c-.46.05-1.11.23-1,.49s-.31.5-.81.5a10.27,10.27,0,0,1-2.24-.38,10.77,10.77,0,0,0-2.82,0l0,.1c-.19.28-1.68,1-2,.9s-.42.47-1,.6a1.74,1.74,0,0,1-.34.05h-.16l2,4.77h0a6.19,6.19,0,0,1,.08,1.65,3.35,3.35,0,0,0,.68,2.69c-.09-.27-.16-.48-.11-.56s.58.12,1,.13a.56.56,0,0,0,.46-.19c.42-.48-.25-.84-.89-.84H74.3c-.35,0-.33-.24-.11-.41a.76.76,0,0,1,.86,0c.77.47,1.15-.12,1.52-.18s.47-1.4-.68-1.72-1,.85-1.75.07.69-.47.69-1.12S73.68,32.76,73.12,32s.78-1.15,1.09-.59,1.15.47,1.68.25-.56-1.21.4-1.59,1,.28,1.28.41.59-.78,1.58-.78a2.59,2.59,0,0,1,1.43.67c.27-.29.49-.47.49-.72s.61-.47.61-1A3.26,3.26,0,0,0,81.5,27.82Z'
      />
      <circle className='port-center' cx='75.54' cy='37.59' r='0.41' />
      <circle className='port-circle' cx='75.54' cy='37.59' r='0.68' />
      <circle className='port-circle' cx='75.54' cy='37.59' r='0.94' />
    </g>
  );
};

export default Pireaus;
