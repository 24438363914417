import {Form} from 'antd';
import styled from 'styled-components';

export const StyledFormGroupBtn = styled(Form.Item)`
  position: relative;

  & .ant-btn {
    & + .ant-btn {
      margin-left: 12px;

      [dir='rtl'] & {
        margin-left: 0;
        margin-right: 12px;
      }
    }
  }
`;
