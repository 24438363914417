// ForJWT Auth
import {getUserFromJwtAuth} from '@erkport/helpers/AuthHelper';
import {useJWTAuth, useJWTAuthActions} from '@erkport/context/AuthProvider';
import {useInfoViewActionsContext} from '@erkport/context/AppContextProvider/InfoViewContextProvider';

export const useAuthUser = () => {
  const {user, isAuthenticated, isLoading} = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    user: getUserFromJwtAuth(user),
  };
};

export const useAuthMethod = () => {
  const {signInUser, signUpUser, logout} = useJWTAuthActions();
  const {fetchError} = useInfoViewActionsContext();

  return {
    signInUser: (values) => {
      try {
        signInUser(values);
      } catch (error) {
        fetchError('invalid login!');
      }
    },
    logout,
    signUpUser,
  };
};
