import React from 'react';
import {PortType} from '..';

const Izmir: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='other-subregion'
        d='M88.21,37.42A.76.76,0,0,1,88,38l-.28.23c-.21.19-.21.19-1.75.81l-1.4.56c-.22-.29-1.3-.45-1.33-.73s-1.65-1-1.06-1.09.5-.78,0-1.15a1,1,0,0,1-.06-1.46c.4-.38-.4-1.25-.18-1.56a.3.3,0,0,0,0-.36,5.53,5.53,0,0,1,2.62,0c.37.13.71.23,1,.33a3.8,3.8,0,0,1,1.83.86A5.63,5.63,0,0,1,88.21,37.42Z'
      />
      <circle className='port-center' cx='81.82' cy='36.19' r='0.41' />
      <circle className='port-circle' cx='81.82' cy='36.19' r='0.68' />
      <circle className='port-circle' cx='81.82' cy='36.19' r='0.94' />
    </g>
  );
};

export default Izmir;
