import React from 'react';
import {HiOutlineChartSquareBar} from 'react-icons/hi';

const routesConfig = [
  {
    id: 'vessel-story',
    messageId: 'sidebar.vessel',
    icon: <HiOutlineChartSquareBar />,
    path: '/vessel',
  },
  {
    id: 'profitability',
    messageId: 'sidebar.profitability',
    icon: <HiOutlineChartSquareBar />,
    type: 'collapse',
    children: [
      {
        id: 'profitability-summary',
        messageId: 'sidebar.profitabilitySummary',
        path: '/profitability/summary',
      },
      {
        id: 'profitability-vessel',
        messageId: 'sidebar.profitabilityVessel',
        path: '/profitability/vessel',
      },
      {
        id: 'profitability-customer',
        messageId: 'sidebar.profitabilityCustomer',
        path: '/profitability/customer',
      },
      {
        id: 'profitability-port',
        messageId: 'sidebar.profitabilityPort',
        path: '/profitability/port',
      },
    ],
  },
  {
    id: 'port-map',
    messageId: 'sidebar.portMap',
    icon: <HiOutlineChartSquareBar />,
    type: 'collapse',
    children: [
      {
        id: 'port-map-europe',
        messageId: 'sidebar.portMapEuropeCluster',
        path: '/port-map/europe-cluster',
      },
      {
        id: 'port-map-port',
        messageId: 'sidebar.portMapEuropePort',
        path: '/port-map/europe-port',
      },
      {
        id: 'port-map-world',
        messageId: 'sidebar.portMapWorld',
        path: '/port-map/world',
      },
    ],
  },
];
export default routesConfig;
