import React from 'react';
import AppSelect from '@erkport/components/AppSelect';
import {StyledPageFilter} from './index.styled';
import AppDatePicker from '../AppDatePicker';
import moment from '../../services/moment';
import {Col} from 'antd';
import type {Moment} from 'moment';

type DateFilterType = {
  onChange: (value: Moment, dateString: string) => void;
  defaultValue?: Moment;
  allowClear?: boolean;
};

const DateFilter: React.FC<DateFilterType> = ({
  onChange,
  defaultValue = moment(),
  allowClear = true,
}) => {
  return (
    <StyledPageFilter>
      <AppDatePicker
        onChange={onChange}
        defaultValue={defaultValue}
        format={'DD.MM.YYYY'}
        allowClear={allowClear}
      />
    </StyledPageFilter>
  );
};

export default DateFilter;
