import React from 'react';
import {PortType} from '..';

const StNazaire: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='continental-subregion'
        d='M31.87,17.21c-3.06,2.67-6.11,5.49-7.83,7.25-.26-.15-.7-.33-1.12-.55a.3.3,0,0,0,.13,0c.49-.25,1.15-3,1-3.8s.62-1.83.31-2.52.47-1.37-.56-1.65a2.06,2.06,0,0,1-1.65-1.8c0-.78-.15-.53-.68-.93s-.78.06-1.84-.5-2.49-.5-2.8-.9.16-.85-.25-1.31.22-1.09,1.31-1,1.37-.47,2.06-.21.71,1,1.27.62,1.43.4,1.84.09a4.59,4.59,0,0,0,2,1c1.05.1,3.69,1.44,4.39,2.93A16.56,16.56,0,0,0,31.87,17.21Z'
      />
      <circle className='port-center' cx='21.82' cy='13.46' r='0.41' />
      <circle className='port-circle' cx='21.82' cy='13.46' r='0.68' />
      <circle className='port-circle' cx='21.82' cy='13.46' r='0.94' />
    </g>
  );
};

export default StNazaire;
