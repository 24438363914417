export const drawChart = (container, data) => {
  document.getElementById(container).innerHTML = '';

  var secondSeriesData = data.map((x) => ({x: x.x, value: x.waitErk}));

  var thirdSeriesData = data.map((x) => ({...x, value: x.waitTotal}));

  const maxData = Math.floor(Math.max(...data.map((x) => x.waitErk)));
  // create line chart
  var chart = window.anychart.line();

  // turn on chart animation
  chart.animation(true);

  // turn on the crosshair
  chart.crosshair().enabled(false);

  // set tooltip mode to point
  chart.tooltip().enabled(false);

  // set yAxis title
  chart.yAxis().title('');
  chart.xAxis().title('');
  chart
    .xAxis()
    .labels()
    .format(function () {
      return this.value.toUpperCase();
    });

  // var thirdseries = chart.column(thirdseriesdata);
  // thirdseries.name('');
  // thirdseries.fill('#8bbef2').stroke('gray');
  // thirdseries.labels().enabled(true).fontcolor('gray').position('top');

  var secondSeries = chart.column(secondSeriesData);
  secondSeries.name('');
  secondSeries.fill('#3873cb').stroke('rgb(29, 66, 138)');
  secondSeries.labels().enabled(true).fontColor('white').position('center');

  chart.barsPadding(-1);
  chart.barGroupsPadding(1);
  // turn the legend on
  chart.legend().enabled(false);

  chart.credits().enabled(false);

  // set container id for the chart
  chart.container(container);
  // initiate chart drawing

  chart.draw();
};
