import React from 'react';
import {VesselStorySectionProps} from '..';
import {CaretUpOutlined} from '@ant-design/icons';

import {StyledVesselStoryPortSection} from './index.styled';

const VesselStoryPortSection: React.FC<VesselStorySectionProps> = ({
  fillRate,
  durationRate,
  portName,
  load,
  unload,
  threeMonths,
}) => {
  return (
    <StyledVesselStoryPortSection
      widthRatio={threeMonths ? 0.1 : durationRate}
      highlightRatio={fillRate * 100}
    >
      <div className='load-info'>
        {load}
        <CaretUpOutlined
          style={{marginTop: -3, fontSize: threeMonths ? 16 : 20}}
        />
      </div>
      <span className='port-name'>{threeMonths ? '' : portName}</span>
      <div className='vessel-story-port-section'></div>
      <div className='unload-info'>
        <CaretUpOutlined
          style={{marginTop: -3, fontSize: threeMonths ? 16 : 20}}
        />
        {unload}
        <div style={{fontWeight: 500}}>{threeMonths ? portName : ''}</div>
      </div>
    </StyledVesselStoryPortSection>
  );
};

export default VesselStoryPortSection;
