import React from 'react';
import {RegionType} from '..';

const Continental: React.FC<RegionType> = ({isSelected, onClick}) => {
  return (
    <g
      onClick={onClick}
      className={`continental region ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='continental-subregion'
        d='M32.2,3.3c0,.33.56,1.12.75.79s.65.42,1.07.66,1.26.51,1.31.79.18.89.6.7.94-.84.94-.47.14,1.08.6,1,.61.8,1.08.7l.33,0c-.05-1,.32-1.55.74-1.78.1-.19.38-.38.42-.61s-.62-.94-.56-1.31c0,0,0-.05,0-.07a4.25,4.25,0,0,1-.46-.4c-.57-.57-1.14-.66-1.52-1A1.06,1.06,0,0,0,36.08,2a3.59,3.59,0,0,1-2,.66,2.51,2.51,0,0,1-.75-.26,4.74,4.74,0,0,1-1.4.6C32,3.15,32.17,3.08,32.2,3.3Z'
      />

      <path
        className='continental-subregion'
        d='M43.21,8.86c-.28.05-.51-.38-1.12-.38a1.63,1.63,0,0,1-1.35-.65c-.28-.37-1.73-.46-2.2-.37s-.6-.8-1.07-.7-.61-.61-.61-1-.51.28-.93.47-.56-.43-.61-.7S34.44,5,34,4.75s-.89-1-1.08-.65-.7-.47-.74-.8S32,3.16,31.92,3a1.87,1.87,0,0,1-.47,0c-.75,0-1.4.56-1.28,1.81S29.27,6.25,28,6.34s-.84,1.09-1.36,1.49-2.4-.21-2.65-.9-1.93,0-1.21.81.68,1.93.28,2.24-1.28-.5-1.84-.09-.59-.38-1.27-.62-1,.3-2.06.21-1.71.53-1.31,1-.06.9.25,1.31,1.75.34,2.8.9,1.31.09,1.84.5.65.15.68.93a2.06,2.06,0,0,0,1.65,1.8c1,.28.25,1,.56,1.65s-.46,1.68-.31,2.52-.5,3.55-1,3.8a.3.3,0,0,1-.13,0c.42.22.86.4,1.12.55,3.89-4,14.49-13.34,16.85-14.06a24.46,24.46,0,0,0,3-1.17C43.9,9,43.42,8.82,43.21,8.86Z'
      />

      <circle className='port-center' cx='31.96' cy='3.29' r='0.41' />
      <circle className='port-circle' cx='31.96' cy='3.29' r='0.68' />
      <circle className='port-circle' cx='31.96' cy='3.29' r='0.94' />

      <circle className='port-center' cx='33.96' cy='2.29' r='0.41' />
      <circle className='port-circle' cx='33.96' cy='2.29' r='0.68' />
      <circle className='port-circle' cx='33.96' cy='2.29' r='0.94' />

      <circle className='port-center' cx='21.82' cy='13.46' r='0.41' />
      <circle className='port-circle' cx='21.82' cy='13.46' r='0.68' />
      <circle className='port-circle' cx='21.82' cy='13.46' r='0.94' />

      <circle className='port-center' cx='26.65' cy='7.38' r='0.41' />
      <circle className='port-circle' cx='26.65' cy='7.38' r='0.68' />
      <circle className='port-circle' cx='26.65' cy='7.38' r='0.94' />

      <path
        className='continental-subregion'
        d='M33.66,33c-.16-1.06-2.3.32-1.84.62C32.76,34.28,33.81,34.1,33.66,33ZM22.92,23.91c-.63.13-2.44-.54-3.64-.39s-5.35-.19-6.94-.06-1.68-.66-2.55-.66-.5,1.06-1.74,1.09-1.37.81-.47,1.53c.51.4.45,1.26.38,2a.79.79,0,0,0,.56-.24c.38-.45.76-.38.76.08s.83.3,1.28.3.31.38.84,0,1.28-.22,1.28.23,1.14.23.53.83-1.36.76-1.36,1.21.68,1.44.15,2,.15,1.44-.37,1.44-1.14.23-.54.84,1.06,1.13.61,1.13-.76.91-.53,1.14,1.29.91.46,1.06-.84.83-.84,1.29a1.55,1.55,0,0,0,.29.64,1.69,1.69,0,0,1,.17-.33c.36-.49,2.56-.82,4.11-2.37s5.1-7.59,7.4-10.8A18.47,18.47,0,0,1,24,24.46C23.78,24.31,23.34,24.13,22.92,23.91Z'
      />
      <path
        className='continental-subregion'
        d='M11.63,37.45c.83-.15-.23-.83-.46-1.06s.08-1.13.53-1.13,0-.53-.6-1.14,0-.83.53-.83-.15-.91.37-1.44-.15-1.51-.15-2,.76-.6,1.37-1.21-.53-.38-.53-.83-.76-.61-1.29-.23-.38,0-.83,0-1.29.15-1.29-.3-.38-.53-.76-.08a.79.79,0,0,1-.56.24A2.91,2.91,0,0,0,8,28.9c.4.65-.5,4.1-1.31,5s0,1.18.81,1.83.28,3,.28,3.58.75.41,1.62.41a11.29,11.29,0,0,0,1.68-.33,1.6,1.6,0,0,1-.31-.69C10.79,38.29,10.79,37.61,11.63,37.45Z'
      />
      <circle className='port-center' cx='8.22' cy='28.7' r='0.41' />
      <circle className='port-circle' cx='8.22' cy='28.7' r='0.68' />
      <circle className='port-circle' cx='8.22' cy='28.7' r='0.94' />

      <circle className='port-center' cx='7.44' cy='36.69' r='0.41' />
      <circle className='port-circle' cx='7.44' cy='36.69' r='0.68' />
      <circle className='port-circle' cx='7.44' cy='36.69' r='0.94' />
    </g>
  );
};

export default Continental;
