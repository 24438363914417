import React from 'react';
import {PortType} from '..';

const Savona: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='baleric-subregion'
        d='M39.9,15.44c-.28.84-.14,1.21.28.7s.93-.33.93.09.56.47.37,1,0,.89.24,1-.14.7-.42.84-.75,0-.42.51.93.56.6,1.07a.64.64,0,0,0,.47,1c.47.05,1.12,0,1,.33a3,3,0,0,0-.23.71,2.76,2.76,0,0,0-1.32.86,4.47,4.47,0,0,1-.52.48,1.3,1.3,0,0,1-.2.15,5.29,5.29,0,0,1-.81-2,8.81,8.81,0,0,0-.65-2.75c-.33-.59-2.44-4.44-3.18-5.8l.2-.16a22.1,22.1,0,0,1,4.67-3.18,24.46,24.46,0,0,0,3-1.17.58.58,0,0,1-.23.33,2.78,2.78,0,0,0-.84,1.73c-.14.6,0,1.58-.47,1.58a1.23,1.23,0,0,0-1.07.89C41.16,14.23,40.18,14.6,39.9,15.44Z'
      />
      <circle className='port-center' cx='42.15' cy='23.21' r='0.41' />
      <circle className='port-circle' cx='42.15' cy='23.21' r='0.68' />
      <circle className='port-circle' cx='42.15' cy='23.21' r='0.94' />
    </g>
  );
};

export default Savona;
