import React from 'react';
import {PortType} from '..';

const Casablanca: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='north-african-subregion'
        d='M18.86,52.52c-.15,0-.17.13-.2.55-.07.68.13,1.3-.28,1.3s-.34.55-1,.62-1.58-.07-1.58.55a1.25,1.25,0,0,1-1.1,1.23c-.68.14-1.85-.14-1.85.41s-.89.14-1.37.41a24.8,24.8,0,0,0-3.36,2.2c0,.13,0,1.49,0,2.87-.26-.44.12-.8-1.51-.8H-1.13a2.84,2.84,0,0,1,1.52-.75c1.68-.28,2-1.46,3.08-2.09S6.37,56.25,6,55.57,5.18,53,6,52.36s.53-1.46,1.18-2,.75-1.8,1.59-1.8a5.24,5.24,0,0,0,2.69-1A9.49,9.49,0,0,0,15.79,50,4.87,4.87,0,0,1,18.86,52.52Z'
      />
      <circle className='port-center' cx='7.21' cy='49.48' r='0.41' />
      <circle className='port-circle' cx='7.21' cy='49.48' r='0.68' />
      <circle className='port-circle' cx='7.21' cy='49.48' r='0.94' />
    </g>
  );
};

export default Casablanca;
