import moment from 'moment';

const defaultPageFilter = {
  year: moment().format('Y'),
  month: moment().format('M'),
  week: moment().format('W'),
  day: moment().format('D'),
  date: moment().format('dd.mm.yyyy'),
  yearToCompare: moment().subtract(1, 'y').format('Y'),
  vessel: '',
};

export default defaultPageFilter;
