import _axios, {apiUrl} from '../axios';

export const getDataGroup1 = async () => {
  const response = await _axios.get(`${apiUrl}/erkport/data-group-1`);
  return response.data;
};

export const getDataGroup2 = async () => {
  const response = await _axios.get(`${apiUrl}/erkport/data-group-2`);
  return response.data;
};

export const getVessels = async () => {
  const response = await _axios.get(`${apiUrl}/erkport/vessels`);
  return response.data;
};

export const getPorts = async () => {
  const response = await _axios.get(`${apiUrl}/erkport/ports`);
  return response.data;
};

export const getYears = async () => {
  const response = await _axios.get(`${apiUrl}/erkport/years`);
  return response.data;
};

export const getMonths = async (year?: string) => {
  const response = await _axios.get(
    `${apiUrl}/erkport/months${year ? '?year=' + year : ''}`,
  );
  return response.data;
};
