import React from 'react';
import {useNavigate} from 'react-router-dom';
import AppAnimate from '@erkport/components/AppAnimate';
import {ReactComponent as Logo} from '../../../../assets/icon/403.svg';
import {
  StyledErrorButton,
  StyledErrorContainer,
  StyledErrorContent,
  StyledErrorImage,
  StyledErrorPara,
} from '../index.styled';
import {useIntl} from 'react-intl';

const Error401 = () => {
  const {messages} = useIntl();
  const navigate = useNavigate();

  const onGoBackToHome = () => {
    navigate('/');
  };

  return (
    <>
      <AppAnimate animation='transition.slideUpIn' delay={200}>
        <StyledErrorContainer key='a'>
          <StyledErrorImage>
            <Logo />
          </StyledErrorImage>
          <StyledErrorContent>
            <h3>{messages['error.unauthorized'] as string}</h3>
            <StyledErrorPara>
              <p className='mb-0'>
                {messages['error.unauthorizedMessage'] as string}
              </p>
            </StyledErrorPara>
            <StyledErrorButton type='primary' onClick={onGoBackToHome}>
              {messages['error.goBackToHome'] as string}
            </StyledErrorButton>
          </StyledErrorContent>
        </StyledErrorContainer>
      </AppAnimate>
    </>
  );
};

export default Error401;
