import axios from 'axios';

const _axios = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export default _axios as any;

const environments = {
  development: 'http://localhost:5000',
  production: 'https://erkport-api.buyukresim.net',
  erkport: 'http://192.168.34.46:443',
};

const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
const baseUrl = environments[env];

export const uploadUrl = `${baseUrl}/upload`;
export const apiUrl = `${baseUrl}/api`;
export const authUrl = `${baseUrl}/auth`;
export const adminUrl = `${baseUrl}/admin`;
