import React, {useContext} from 'react';
import {Checkbox, Col} from 'antd';
import AppRowContainer from '../AppRowContainer';
import {monthNames} from '@erkport/constants/AppConst';
import AppSelect from '../AppSelect';
import {useIntl} from 'react-intl';
import DateFilter from '../AppPageFilter/DateFilter';
import {Moment} from 'moment';

const labelStyle = {paddingLeft: 10, fontSize: 13, paddingBottom: 2};

type PageFilterType = {
  yearState: {
    year: any;
    years: any[];
  };
  setYear: (year: any) => void;
  monthState: {
    month: any;
    months: any[];
  };
  setMonth: (month: any) => void;
  weekState: {
    week: any;
    weeks: any[];
  };
  setWeek: (week: any) => void;
  portState: {
    port: any;
    ports: any[];
  };
  setPort: (port: any) => void;
  vesselState: {
    vessel: any;
    vessels: any[];
  };
  setVessel: (vessel: any) => void;
  customerState: {
    customer: any;
    customers: any[];
  };
  setCustomer: (customer: any) => void;
  date: Moment;
  setDate?: (date: Moment) => void;
  dateConfig?: {
    allowClear?: boolean;
    labelKey?: string;
  };
  loadUnit: string;
  setLoadUnit?: (loadUnit: string) => void;
  compareYear: any;
  setCompareYear?: (compareYear: any) => void;
};

const PageFilter: React.FC<PageFilterType> = ({
  yearState,
  setYear,
  monthState,
  setMonth,
  weekState,
  setWeek,
  portState,
  setPort,
  vesselState,
  setVessel,
  customerState,
  setCustomer,
  date,
  setDate,
  dateConfig,
  loadUnit,
  setLoadUnit,
  compareYear,
  setCompareYear,
}) => {
  const {messages} = useIntl();
  const showLabel =
    portState?.ports?.[0] ||
    vesselState?.vessels?.[0] ||
    customerState?.customers?.[0] ||
    setCompareYear;
  return (
    <AppRowContainer>
      {yearState?.years?.[0] && (
        <Col xs={12} sm={6} lg={3}>
          {showLabel && <br />}
          <AppSelect
            id='yearsFilter'
            options={yearState?.years}
            onChange={setYear}
            defaultValue={yearState?.year}
          />
        </Col>
      )}
      {monthState?.months?.[0] && (
        <Col xs={12} sm={6} lg={3}>
          {showLabel && <br />}
          <AppSelect
            id='monthsFilter'
            options={monthState?.months.map((x) => ({
              value: x,
              label: messages[monthNames[x - 1]] as string,
            }))}
            defaultValue={monthState?.month}
            onChange={setMonth}
          />
        </Col>
      )}
      {weekState?.weeks?.[0] && (
        <Col xs={12} sm={6} lg={4}>
          {showLabel && <br />}
          <AppSelect
            id='weeksFilter'
            options={weekState?.weeks.map((x) => ({
              value: x,
              label: `${x}. hafta`,
            }))}
            defaultValue={weekState?.week}
            onChange={setWeek}
          />
        </Col>
      )}
      {yearState?.years?.[0] && setCompareYear && (
        <Col xs={12} sm={6} lg={3}>
          <div style={labelStyle}>
            {messages['common.compareyear'] as string}
          </div>
          <AppSelect
            id='compareYearsFilter'
            options={yearState?.years}
            onChange={setCompareYear}
            defaultValue={compareYear}
          />
        </Col>
      )}
      {portState?.ports?.[0] && (
        <Col xs={12} sm={6} lg={4}>
          <div style={labelStyle}>{messages['common.port'] as string}</div>
          <AppSelect
            id='portsFilter'
            options={portState?.ports}
            onChange={setPort}
          />
        </Col>
      )}
      {vesselState?.vessels?.[0] && (
        <Col xs={12} sm={6} lg={4}>
          <div style={labelStyle}>{messages['common.vessel'] as string}</div>
          <AppSelect
            id='vesselsFilter'
            options={vesselState?.vessels?.sort((a, b) => (a > b ? 1 : -1))}
            onChange={setVessel}
          />
        </Col>
      )}
      {customerState?.customers?.[0] && (
        <Col xs={12} sm={6} lg={4}>
          <div style={labelStyle}>{messages['common.customer'] as string}</div>
          <AppSelect
            id='customersFilter'
            options={customerState?.customers}
            onChange={setCustomer}
          />
        </Col>
      )}
      {setDate && (
        <Col xs={12} sm={6} lg={4}>
          <div style={labelStyle}>
            {messages[dateConfig?.labelKey] as string}
          </div>
          <DateFilter
            defaultValue={date}
            onChange={(value) => setDate(value)}
            allowClear={dateConfig?.allowClear}
          />
        </Col>
      )}
      {setLoadUnit && (
        <Col xs={12} sm={3} lg={2}>
          <div style={labelStyle}>{messages['common.unit'] as string}</div>
          <AppSelect
            id='loadUnitFilter'
            options={['M2', 'M3']}
            onChange={setLoadUnit}
            defaultValue={loadUnit}
          />
        </Col>
      )}
    </AppRowContainer>
  );
};

export default PageFilter;
