import {monthNames} from '@erkport/constants/AppConst';
import {formatNumberMK} from '@erkport/helpers/NumberFormatter';
import React from 'react';
import useIntl from 'react-intl/lib/src/components/useIntl';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from 'recharts';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';

type StatGraphsProps = {
  data: {x: string; y: number}[];
  strokeColor: string;
};

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  const {messages} = useIntl();

  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: 'rgba(0,0,0,0.7)',
          padding: 10,
          borderRadius: 5,
        }}
      >
        <p style={{color: '#fff'}}>{`${
          messages[monthNames[Number(payload[0]?.payload?.x) - 1]]
        } : ${formatNumberMK(payload[0].value, 2)}`}</p>
      </div>
    );
  }

  return null;
};

const StatGraphs: React.FC<StatGraphsProps> = ({data, strokeColor}) => {
  return (
    <ResponsiveContainer width='100%' height={80}>
      <AreaChart data={data} margin={{top: 10, right: 0, left: 0, bottom: 10}}>
        <defs>
          <linearGradient
            id={`color-${strokeColor}`}
            x1='0'
            y1='0'
            x2='0'
            y2='1'
          >
            <stop offset='5%' stopColor={strokeColor} stopOpacity={0.15} />
            <stop offset='95%' stopColor={strokeColor} stopOpacity={0.01} />
          </linearGradient>
        </defs>
        <Tooltip content={<CustomTooltip />} />
        <Area
          type='monotone'
          dataKey='y'
          stroke={strokeColor}
          strokeWidth={2}
          fillOpacity={1}
          fill={`url(#color-${strokeColor})`}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default StatGraphs;
