import React from 'react';
import {Dropdown} from 'antd';
import AppLogo from '../AppLogo';
import {useIntl} from 'react-intl';
import AppLanguageSwitcher from '../../../../components/AppLanguageSwitcher';
import AppHeaderMessages from '../../../../components/AppHeaderMessages';
import AppNotifications from '../../../../components/AppNotifications';
import {FiMoreVertical} from 'react-icons/fi';
import {AiOutlineMenu} from 'react-icons/ai';
import {
  StyledDrawerLayoutHeader,
  StyledDrawerLayoutHeaderDesk,
} from './index.styled';
import {StyledDropdownWrapper} from './index.styled';
import UserInfo from '../UserInfo';

const items = [
  {key: 1, label: <AppHeaderMessages />},
  {key: 2, label: <AppNotifications />},
  {key: 3, label: <AppLanguageSwitcher />},
];

type AppHeaderProps = {
  showDrawer: () => void;
};

const AppHeader = ({showDrawer}: AppHeaderProps) => {
  const {messages} = useIntl();

  return (
    <StyledDrawerLayoutHeader>
      <a className='trigger' onClick={showDrawer}>
        <AiOutlineMenu />
      </a>
      <AppLogo />
      <StyledDrawerLayoutHeaderDesk>
        <UserInfo hasColor />
        <AppLanguageSwitcher />
      </StyledDrawerLayoutHeaderDesk>
    </StyledDrawerLayoutHeader>
  );
};

export default AppHeader;
