import React from 'react';
import AppContentView from '../../components/AppContentView';
import generateRoutes from '../../helpers/RouteGenerator';
import BaseLayout from '../BaseLayout';
import {useAuthUser} from '@erkport/hooks/AuthHooks';
import {
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from '../AppRoutes';
import {useRoutes} from 'react-router-dom';
import routesConfig from '../AppRoutes/routeConfig';
import AuthWrapper from '@erkport/modules/auth/AuthWrapper';
import {RouterConfigData} from '../../types/Apps';
import AppInfoView from '@erkport/components/AppInfoView';

const AppLayout = () => {
  const {user, isAuthenticated} = useAuthUser();
  const generatedRoutes = generateRoutes({
    isAuthenticated: isAuthenticated,
    userRole: user?.role,
    unAuthorizedStructure,
    authorizedStructure,
    anonymousStructure,
  });
  const routes = useRoutes(generatedRoutes);

  return (
    <>
      {isAuthenticated ? (
        <BaseLayout
          routes={routes}
          routesConfig={routesConfig as RouterConfigData[]}
        />
      ) : (
        <AuthWrapper>
          <AppContentView routes={routes} />
        </AuthWrapper>
      )}
    </>
  );
};

export default AppLayout;
