import React from 'react';
import {VesselStorySectionProps} from '..';

import {StyledVesselStoryVoyageSection} from './index.styled';

const VesselStoryVoyageSection: React.FC<VesselStorySectionProps> = ({
  fillRate,
  duration,
  durationRate,
  threeMonths,
}) => {
  return (
    <StyledVesselStoryVoyageSection
      widthRatio={durationRate}
      highlightRatio={fillRate}
    >
      <div className='vessel-story-voyage-section'>
        <div className='highlighted' style={{fontSize: threeMonths ? 16 : 20}}>
          <span>{`${fillRate}%`}</span>
        </div>
      </div>
      <div className='duration' style={{fontSize: threeMonths ? 12 : 14}}>
        {duration}d
      </div>
    </StyledVesselStoryVoyageSection>
  );
};

export default VesselStoryVoyageSection;
