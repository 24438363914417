import React from 'react';
import {
  EuropePortMap,
  WorldPortMap,
  CityPortMap,
} from '@erkport/modules/dashboard/portMap';
import VesselStory from '@erkport/modules/dashboard/vesselStory';
import ProfitabilityVessel from '@erkport/modules/dashboard/profitability/vessel';
import ProfitabilityPort from '@erkport/modules/dashboard/profitability/port';
import ProfitabilityCustomer from '@erkport/modules/dashboard/profitability/customer';
import {Navigate, useLocation} from 'react-router-dom';
import {RoutePermittedRole} from '../../constants/AppEnums';
import ProfitabilitySummary from '@erkport/modules/dashboard/profitability/summary';

const Page = () => {
  const {pathname} = useLocation();
  return (
    <div>
      <div>{pathname}</div>
    </div>
  );
};

export const dashboardPagesConfigs = [
  {
    path: '/vessel',
    element: <VesselStory />,
  },
  {
    path: '/port-map/europe-cluster',
    element: <EuropePortMap />,
  },
  {
    path: '/port-map/world',
    element: <WorldPortMap />,
  },
  {
    path: '/port-map/europe-port',
    element: <CityPortMap />,
  },
  {
    path: '/profitability/summary',
    element: <ProfitabilitySummary />,
  },
  {
    path: '/profitability/vessel',
    element: <ProfitabilityVessel />,
  },
  {
    path: '/profitability/port',
    element: <ProfitabilityPort />,
  },
  {
    path: '/profitability/customer',
    element: <ProfitabilityCustomer />,
  },
  {
    path: '/*',
    element: <Page />,
  },
];
