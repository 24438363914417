import React from 'react';
import {PortType} from '..';

const LeHavre: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='continental-subregion'
        d='M37.59,12.51C36,13.73,34,15.35,32.06,17.05l-.19.16a16.56,16.56,0,0,1-2.41-3.29c-.7-1.49-3.34-2.83-4.39-2.93a4.59,4.59,0,0,1-2-1,.58.58,0,0,0,.15-.19,2,2,0,0,0-.43-2c-.72-.81,1-1.49,1.21-.81s2.12,1.31,2.65.9.09-1.4,1.36-1.49c.88-.06,1.64-.14,2-.59a6,6,0,0,0,1.64.7,9.1,9.1,0,0,1,4.57,3.2A23.69,23.69,0,0,1,37.59,12.51Z'
      />
      <circle className='port-center' cx='26.65' cy='7.38' r='0.41' />
      <circle className='port-circle' cx='26.65' cy='7.38' r='0.68' />
      <circle className='port-circle' cx='26.65' cy='7.38' r='0.94' />
    </g>
  );
};

export default LeHavre;
