import styled from 'styled-components';

export const StyledFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h5 {
    margin-bottom: 0 !important;
  }
`;

export const StyledFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px 16px 8px;
`;

export const StyledIconWrapper = styled.span`
  margin-right: 12px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const StyledTitleWrapper = styled.p`
  color: ${({theme}) => theme.palette.text.secondary};
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
`;
