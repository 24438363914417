import {ReloadOutlined} from '@ant-design/icons';
import AppCard from '@erkport/components/AppCard';
import Loadable from '@erkport/components/AppLoadable';
import {Typography} from 'antd';
import {isNull} from 'lodash';
import React, {ReactElement} from 'react';
import {
  StyledFlexContainer,
  StyledFlexWrapper,
  StyledIconWrapper,
  StyledTitleWrapper,
} from './index.styled';

type InfoCardType = {
  selected?: boolean;
  title: string;
  data: any[];
  icon: ReactElement;
  onClick: (data: any[]) => void;
};

const InfoCard: React.FC<InfoCardType> = ({
  selected = false,
  title,
  data,
  icon,
  onClick,
}) => {
  return (
    <Loadable isLoading={isNull(data)}>
      <AppCard
        className='cursor-pointer card-hover no-card-space'
        onClick={() => onClick(data)}
        style={{backgroundColor: selected ? '#ddd' : 'white'}}
      >
        <StyledFlexWrapper>
          <StyledFlexContainer>
            <StyledIconWrapper
              style={{
                color: '#0A8FDC',
                backgroundColor: '#0A8FDC22',
                fontSize: 20,
              }}
            >
              {icon}
            </StyledIconWrapper>
            <div
              style={{marginRight: 8, overflow: 'hidden', textAlign: 'right'}}
            >
              <Typography.Title style={{marginBottom: '0.2rem'}} level={4}>
                {data.length}
              </Typography.Title>
              <StyledTitleWrapper>{title.toUpperCase()}</StyledTitleWrapper>
            </div>
          </StyledFlexContainer>
        </StyledFlexWrapper>
      </AppCard>
    </Loadable>
  );
};

export default InfoCard;
