import React from 'react';
import {PortType} from '..';

const Venice: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='adriatic-subregion'
        d='M55.49,23.57c-.29.38-.69.86-1.19,1.43l-.39.48c-.06-.07-.12-.13-.17-.2a37.42,37.42,0,0,1-4.33-6.2,1.77,1.77,0,0,1,.9.27,4.17,4.17,0,0,0,1.88.34c.26,0,.52,0,.77.06-.09.43-.49.71-.13,1.49s1.75,1.11,2.51,2.11A1.07,1.07,0,0,1,55.49,23.57Z'
      />
      <circle className='port-center' cx='53' cy='20.67' r='0.41' />
      <circle className='port-circle' cx='53' cy='20.67' r='0.68' />
      <circle className='port-circle' cx='53' cy='20.67' r='0.94' />
    </g>
  );
};

export default Venice;
