import React from 'react';
import {slugifyPort} from '../page';
import {cities} from './cities';

import './index.css';
import Undefined from './Undefined';

type MapType = {
  selectedPort: string;
  onPortSelected: (port: string) => void;
  portsData: any;
};

export type PortType = {
  importExportStatus: any;
  isSelected: boolean;
  onClick: () => void;
};

const Map: React.FC<MapType> = ({portsData, selectedPort, onPortSelected}) => {
  const decidePortColor = (port: string): string => {
    const exportData = portsData[port]?.export || 0;
    const importData = portsData[port]?.import || 0;
    const exportImportRatio = (importData - exportData) / (exportData || 1);

    return exportImportRatio < -0.02
      ? 'red'
      : exportImportRatio > 0.02
      ? 'blue'
      : 'grey';
  };

  return (
    <svg id='objects' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 130 55'>
      <Undefined />
      {cities.map(({name, component: Component}) => (
        <Component
          key={slugifyPort(name)}
          importExportStatus={decidePortColor(slugifyPort(name))}
          isSelected={slugifyPort(selectedPort) == slugifyPort(name)}
          onClick={() => onPortSelected(name)}
        />
      ))}
      <text className='port-name' transform='translate(58 26)'>
        VASTO
      </text>
      <text className='port-name' transform='translate(55 16.5)'>
        KOPER
      </text>
      <text className='port-name' transform='translate(47.5 21)'>
        VENICE
      </text>
      <text className='port-name' transform='translate(46 17.5)'>
        MONFALCONE
      </text>
      <text className='region-name' transform='translate(55.65 24.42)'>
        ADRIATIC
      </text>
      <text className='port-name' transform='translate(53 32.5)'>
        SALERNO
      </text>
      <text className='port-name' transform='translate(49.2 24.2)'>
        LIVORNO
      </text>
      <text className='port-name' transform='translate(41 21.8)'>
        SAVONA
      </text>
      <text className='port-name' transform='translate(38 26.2)'>
        TOULON
      </text>
      <text className='port-name' transform='translate(36.2 22)'>
        FOS
      </text>
      <text className='port-name' transform='translate(32 23)'>
        SETE
      </text>
      <text className='port-name' transform='translate(24 29)'>
        TARRAGONA
      </text>
      <text className='port-name' transform='translate(28 26.2)'>
        BARCELONA
      </text>
      <text className='port-name' transform='translate(22 34)'>
        VALENCIA
      </text>
      <text className='region-name' transform='translate(36.38 29.54)'>
        BALERIC
      </text>
      <text className='port-name' transform='translate(33 5)'>
        ZEEBRUGGE
      </text>
      <text className='port-name' transform='translate(35.18 2.91)'>
        ANTWERP
      </text>
      <text className='port-name' transform='translate(22.98 12.78)'>
        ST. NAZAIRE
        <tspan x='0.22' y='1'>
          MONTOIR
        </tspan>
      </text>
      <text className='port-name' transform='translate(28 8)'>
        LE HAVRE
      </text>
      <text className='port-name' transform='translate(9.19 28.61)'>
        VIGO
      </text>
      <text className='port-name' transform='translate(8.22 35.94)'>
        SETUBAL
      </text>
      <text className='region-name' transform='translate(5.97 19.32)'>
        CONTINENTAL
      </text>
      <text className='port-name' transform='translate(8 51)'>
        CASABLANCA
      </text>
      <text className='port-name' transform='translate(39.71 41.12)'>
        DJEN-DJEN
      </text>
      <text className='port-name' transform='translate(27.89 42.08)'>
        MOSTAGANEM
      </text>
      <text className='port-name' transform='translate(13.93 45)'>
        TANGER
      </text>
      <text className='region-name' transform='translate(15.62 49.89)'>
        NORH AFRICAN
      </text>
      <text className='port-name' transform='translate(82 27)'>
        HAYDARPAŞA
        <tspan x='0.51' y='1'>
          İSTANBUL
        </tspan>
      </text>
      <text className='port-name' transform='translate(80 32.8)'>
        GEMPORT BORUSAN
        <tspan x='1.34' y='1'>
          GEMLİK
        </tspan>
      </text>
      <text className='port-name' transform='translate(83 36)'>
        ALSANCAK PORT
        <tspan x='1.22' y='1'>
          İZMİR
        </tspan>
      </text>
      <text className='port-name' transform='translate(93 39.5)'>
        YAZICI PORT
        <tspan x='0' y='1'>
          İSKENDERUN
        </tspan>
      </text>
      <text className='port-name' transform='translate(76.2 39)'>
        PIREAUS
      </text>
      <text className='port-name' transform='translate(95 49.2)'>
        ASHDOD
        <tspan x='0.4' y='1'>
          PORT
        </tspan>
      </text>
    </svg>
  );
};

export default Map;
