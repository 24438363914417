import React from 'react';
import {PortType} from '..';

const Vasto: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='adriatic-subregion'
        d='M64.78,31.83c-.06,1.05-.5.59-1.59-.1a1.63,1.63,0,0,0-.88-.27,1.18,1.18,0,0,0-.27,0,1,1,0,0,0-.32.11l-.13.07a1.14,1.14,0,0,0-.23.2,33.78,33.78,0,0,1-7.45-6.39L54.3,25c.5-.57.9-1.05,1.19-1.43a2.7,2.7,0,0,1,.29.69c.47,1.84,2,3.43,3.27,3.36s.75.75,1.56,1.25S64.84,30.77,64.78,31.83Z'
      />
      <circle className='port-center' cx='58.02' cy='27.27' r='0.41' />
      <circle className='port-circle' cx='58.02' cy='27.27' r='0.68' />
      <circle className='port-circle' cx='58.02' cy='27.27' r='0.94' />
    </g>
  );
};

export default Vasto;
