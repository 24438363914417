import React from 'react';
import {PortType} from '..';

const Salerno: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='baleric-subregion'
        d='M62.13,34.28c-.5.66-1.12.63-1.21,1.25s-.75,1.74-1.06,1.74-.56-1.06-.09-1.68a2.07,2.07,0,0,0-.1-2.27c-.43-.53,0-1.15-.68-1.24s-.75-.91-1.49-1.25a4.84,4.84,0,0,1-1.84-1.21c-.47-.56-1.24-.47-2-.88a2.28,2.28,0,0,1-.39-.27,9.43,9.43,0,0,0,0-1.32,1.88,1.88,0,0,1,.57-1.63l0,0a33.78,33.78,0,0,0,7.45,6.39l-.09.13a.33.33,0,0,0-.07.13.5.5,0,0,0,0,.13.93.93,0,0,0,0,.37C61.39,33.47,62.63,33.63,62.13,34.28Z'
      />
      <circle className='port-center' cx='57' cy='30.63' r='0.41' />
      <circle className='port-circle' cx='57' cy='30.63' r='0.68' />
      <circle className='port-circle' cx='57' cy='30.63' r='0.94' />
    </g>
  );
};

export default Salerno;
