import React from 'react';
import {useNavigate} from 'react-router-dom';
import clsx from 'clsx';
import {Dropdown} from 'antd';
import {FaChevronDown} from 'react-icons/fa';
import {useThemeContext} from '@erkport/context/AppContextProvider/ThemeContextProvider';
import {useAuthMethod, useAuthUser} from '@erkport/hooks/AuthHooks';
import {
  StyledCrUserDesignation,
  StyledCrUserInfo,
  StyledCrUserInfoAvatar,
  StyledCrUserInfoContent,
  StyledCrUserInfoInner,
  StyledUserArrow,
  StyledUsername,
  StyledUsernameInfo,
} from './index.styled';
import useIntl from 'react-intl/lib/src/components/useIntl';

type UserInfoProps = {
  hasColor?: boolean;
};
const UserInfo: React.FC<UserInfoProps> = ({hasColor}) => {
  const {themeMode} = useThemeContext();
  const {logout} = useAuthMethod();
  const {user} = useAuthUser();
  const navigate = useNavigate();
  const {messages} = useIntl();

  const getUserAvatar = () => {
    if (user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  const items = [
    {
      key: 1,
      label: (
        <div onClick={() => navigate('/profile')}>
          {messages['common.profile'] as string}
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div onClick={() => logout()}>
          {messages['common.logout'] as string}
        </div>
      ),
    },
  ];

  return (
    <>
      <StyledCrUserInfo
        className={clsx('cr-user-info', {
          light: themeMode === 'light',
        })}
      >
        <Dropdown
          menu={{items}}
          trigger={['click']}
          placement='bottomRight'
          overlayStyle={{
            zIndex: 1052,
            minWidth: 150,
          }}
        >
          <StyledCrUserInfoInner className='ant-dropdown-link'>
            {user.photoURL ? (
              <StyledCrUserInfoAvatar src={user.photoURL} />
            ) : (
              <StyledCrUserInfoAvatar>{getUserAvatar()}</StyledCrUserInfoAvatar>
            )}
            <StyledCrUserInfoContent className='cr-user-info-content'>
              <StyledUsernameInfo>
                <StyledUsername
                  className={clsx('text-truncate', {
                    light: themeMode === 'light',
                  })}
                >
                  {user.displayName ? user.displayName : 'admin user '}
                </StyledUsername>
                <StyledUserArrow className='cr-user-arrow'>
                  <FaChevronDown />
                </StyledUserArrow>
              </StyledUsernameInfo>
              <StyledCrUserDesignation className='text-truncate cr-user-designation'>
                {user.role}
              </StyledCrUserDesignation>
            </StyledCrUserInfoContent>
          </StyledCrUserInfoInner>
        </Dropdown>
      </StyledCrUserInfo>
    </>
  );
};

export default UserInfo;
