import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'; // load on demand
import relativeTime from 'dayjs/plugin/relativeTime'; // load on demand
import localizedFormat from 'dayjs/plugin/localizedFormat'; // load on demand

dayjs.extend(localizedFormat);
dayjs.extend(AdvancedFormat); // use plugin
dayjs.extend(relativeTime); // use plugin

export const getDateObject = (dateObject?: string | dayjs.Dayjs) => {
  if (dateObject) return dayjs(dateObject);
  return dayjs();
};

export const getFormattedDate = (
  dateObject?: string | dayjs.Dayjs,
  format = 'MMM DD,YYYY',
) => {
  if (dateObject) return dayjs(dateObject).format(format);
  return '';
};

export const getFormattedDateTime = (
  value = 0,
  unit = 'days',
  format = 'MMM DD,YYYY',
) => {
  if (value === 0) {
    return dayjs().format(format);
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return dayjs().add(value, unit).format(format);
  }
};

export const timeFromNow = (date: string) => {
  const timestamp = dayjs(date).format('X');
  const newDate = dayjs.unix(Number(timestamp));
  return dayjs(newDate).fromNow();
};

export const getTimeFromNow = (date: string) => {
  const timestamp = dayjs(date).format('X');
  const newDate = dayjs.unix(Number(timestamp));
  return dayjs(newDate).fromNow();
};

export type LastMonthsType = {
  year: number;
  month: number;
};
export const getLastMonths = (year: number, month: number, count: number) => {
  year = Number(year);
  month = Number(month);

  const result: LastMonthsType[] = [];
  while (result.length < count) {
    result.push({year, month});
    month--;
    if (month == 0) {
      year--;
      month = 12;
    }
  }
  const sortedResult = result
    .sort((a, b) => (a.month < b.month ? 1 : -1))
    .sort((a, b) => (a.year > b.year ? 1 : -1));

  return sortedResult;
};

export type LastWeeksType = {
  year: number;
  week: number;
};
export const getLastWeeks = (year: number, week: number, count: number) => {
  year = Number(year);
  week = Number(week);

  const result: LastWeeksType[] = [];
  while (result.length < count) {
    result.push({year, week});
    week--;
    if (week == 0) {
      year--;
      week = 53;
    }
  }
  const sortedResult = result
    .sort((a, b) => (a.week < b.week ? 1 : -1))
    .sort((a, b) => (a.year > b.year ? 1 : -1));

  return sortedResult;
};

export const getMonthsInRange = (start: number, end: number) =>
  new Array(end - start + 1).fill(0).map((_, i) => i + start);
