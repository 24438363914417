import React, {useState} from 'react';
import {Button} from 'antd';
import FileDownload from 'js-file-download';
import {CloudDownloadOutlined} from '@ant-design/icons';
import {utils, writeXLSX} from 'xlsx';
import {useIntl} from 'react-intl';

type JsonToExcelExporterType = {
  data: any[];
  fileName: string;
};

const JsonToExcelExporter: React.FC<JsonToExcelExporterType> = ({
  data,
  fileName,
}) => {
  const [loading, setLoading] = useState(false);

  const {messages} = useIntl();

  const download = () => {
    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    const bufferData = writeXLSX(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'buffer',
    });

    setLoading(() => true);

    FileDownload(
      bufferData,
      fileName,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );

    setLoading(() => false);
  };

  return (
    <Button
      type='primary'
      icon={<CloudDownloadOutlined />}
      loading={loading}
      onClick={download}
    >
      {messages['common.export'] as string}
    </Button>
  );
};

export default JsonToExcelExporter;
