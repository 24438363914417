import React from 'react';
import {RegionType} from '..';

const Baleric: React.FC<RegionType> = ({isSelected, onClick}) => {
  return (
    <g
      onClick={onClick}
      className={`baleric region ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='baleric-subregion'
        d='M62.13,34.28c-.5.66-1.12.63-1.21,1.25s-.75,1.74-1.06,1.74-.56-1.06-.09-1.68a2.07,2.07,0,0,0-.1-2.27c-.43-.53,0-1.15-.68-1.24s-.75-.91-1.49-1.25a4.84,4.84,0,0,1-1.84-1.21c-.47-.56-1.24-.47-2-.88s-1.37-1.49-2.87-2.45a4.1,4.1,0,0,1-2-2.55c-.09-.53-.22-.6-.65-1.25a3.23,3.23,0,0,0-3-1.25c-1.09.32-.81.91-1.46,1.25a4.71,4.71,0,0,1-1,.29,3,3,0,0,1,.23-.71c.14-.37-.51-.28-1-.33a.64.64,0,0,1-.47-1c.33-.51-.27-.56-.6-1.07s.14-.37.42-.51.65-.7.42-.84a.79.79,0,0,1-.24-1c.64-.06,1.52-.13,1.79-.13.44,0,.88-1.37,1.06-1.5s.69,1,1.19,1.44.56-.38.56-.75.43-.5.74-.44.84.16,1-.11A36.13,36.13,0,0,0,61.36,31.87a.84.84,0,0,0-.19.76C61.39,33.47,62.63,33.63,62.13,34.28Z'
      />

      <circle className='port-center' cx='57' cy='30.63' r='0.41' />
      <circle className='port-circle' cx='57' cy='30.63' r='0.68' />
      <circle className='port-circle' cx='57' cy='30.63' r='0.94' />
      <circle className='port-center' cx='48.09' cy='23.23' r='0.41' />
      <circle className='port-circle' cx='48.09' cy='23.23' r='0.68' />
      <circle className='port-circle' cx='48.09' cy='23.23' r='0.94' />

      <path
        className='baleric-subregion'
        d='M57.4,36.93c-1.27.28-1.8.37-2.55,0s-2,.34-1.77.84c.12.34.62.78,2.18,1.18s1.74,1.46,2.39,1.5.72-.6.56-1.44.78-1.8.87-2S58.68,36.65,57.4,36.93Z'
      />
      <path
        className='baleric-subregion'
        d='M46.54,24.7c-.34,0-.43.59-1.3,1.12s0,2.92.87,2.8S46.88,24.73,46.54,24.7Z'
      />
      <path
        className='baleric-subregion'
        d='M45.36,29.87c-.68.59-1.25-.11-1.52.37s1,1.49.77,2.71.16,2.73.87,2,1.06-.37,1.47-.9.06-2.49.34-3.43S46,29.27,45.36,29.87Z'
      />

      <path
        className='baleric-subregion'
        d='M39.9,15.44c-.28.84-.14,1.21.28.7s.93-.33.93.09.56.47.37,1,0,.89.24,1-.14.7-.42.84-.75,0-.42.51.93.56.6,1.07a.64.64,0,0,0,.47,1c.47.05,1.12,0,1,.33a3,3,0,0,0-.23.71,2.76,2.76,0,0,0-1.32.86c-1,1.09-1.84,1.18-2.43.81a8.73,8.73,0,0,0-3.86-.84c-1,.16-2.12.87-1.74,2.09a2.94,2.94,0,0,1,.12.54,13.5,13.5,0,0,1-2.16-.07c-.68-.15-2.12-.22-2.5-.6s-1-.68-1-.23-1.06.53-1.67.15-1.59-.68-2-1L24,24.46c3.89-4,14.49-13.34,16.85-14.06a24.46,24.46,0,0,0,3-1.17.58.58,0,0,1-.23.33,2.78,2.78,0,0,0-.84,1.73c-.14.6,0,1.58-.47,1.58a1.23,1.23,0,0,0-1.07.89C41.16,14.23,40.18,14.6,39.9,15.44Z'
      />

      <path
        className='baleric-subregion'
        d='M32.6,28c-.9.37-1.24,1.09-2.3,1.21s-1.93.37-1.93,1-.91.78-1.75,2.05.07,2.71,0,3.08S25.1,36.49,25,37s.06,1.37-.78,1.5a2,2,0,0,0-1.65,1.12,1.42,1.42,0,0,1-1.52.74c-.75,0-1.5.07-2.68.1s-1.43.74-1.93.74-1-.18-1.21.06,0,.63-.5.69c-1.18.15-1.68-2-2.55-2.43a1.86,1.86,0,0,0-1.12-.08.08.08,0,0,1,0,0,1.69,1.69,0,0,1,.17-.33c.36-.49,2.56-.82,4.11-2.37s5.1-7.59,7.4-10.8A18.47,18.47,0,0,1,24,24.46l.08.05c.45.3,1.44.6,2,1s1.67.31,1.67-.15.6-.15,1,.23,1.82.45,2.5.6a13.5,13.5,0,0,0,2.16.07A1.39,1.39,0,0,1,32.6,28Z'
      />

      <circle className='port-center' cx='42.15' cy='23.21' r='0.41' />
      <circle className='port-circle' cx='42.15' cy='23.21' r='0.68' />
      <circle className='port-circle' cx='42.15' cy='23.21' r='0.94' />
      <circle className='port-center' cx='39.15' cy='24.21' r='0.41' />
      <circle className='port-circle' cx='39.15' cy='24.21' r='0.68' />
      <circle className='port-circle' cx='39.15' cy='24.21' r='0.94' />
      <circle className='port-center' cx='37.15' cy='23.21' r='0.41' />
      <circle className='port-circle' cx='37.15' cy='23.21' r='0.68' />
      <circle className='port-circle' cx='37.15' cy='23.21' r='0.94' />
      <circle className='port-center' cx='34.15' cy='24.21' r='0.41' />
      <circle className='port-circle' cx='34.15' cy='24.21' r='0.68' />
      <circle className='port-circle' cx='34.15' cy='24.21' r='0.94' />

      <circle className='port-center' cx='32.89' cy='27.46' r='0.41' />
      <circle className='port-circle' cx='32.89' cy='27.46' r='0.68' />
      <circle className='port-circle' cx='32.89' cy='27.46' r='0.94' />
      <circle className='port-center' cx='29.02' cy='30.03' r='0.41' />
      <circle className='port-circle' cx='29.02' cy='30.03' r='0.68' />
      <circle className='port-circle' cx='29.02' cy='30.03' r='0.94' />
      <circle className='port-center' cx='26.15' cy='35.12' r='0.41' />
      <circle className='port-circle' cx='26.15' cy='35.12' r='0.68' />
      <circle className='port-circle' cx='26.15' cy='35.12' r='0.94' />
    </g>
  );
};

export default Baleric;
