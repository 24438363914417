import React from 'react';
import Loading from './Loading';

type AppLoadableType = {
  isLoading: boolean;
  children: React.ReactNode;
};

const Loadable: React.FC<AppLoadableType> = ({isLoading, children}) => {
  return (
    <>
      {isLoading && <Loading />}
      <div style={{display: isLoading ? 'none' : 'block'}}>{children}</div>
    </>
  );
};

export default Loadable;
