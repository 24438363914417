import React, {useEffect, useState} from 'react';
import AppCard from '@erkport/components/AppCard';
import Loading from '@erkport/components/AppLoadable/Loading';
import AppRowContainer from '@erkport/components/AppRowContainer';
import {PageWithFilterProps} from '@erkport/components/PageWrapper';
import {getLastMonths, getLastWeeks} from '@erkport/helpers/DateHelper';
import {getDataGroup2} from '@erkport/services/erkport';
import {Col} from 'antd';
import ImportExportChart from '../components/importExportChart';
import Map from './map';
import {useIntl} from 'react-intl';

export const slugifyPort = (port?: string) =>
  port?.toLowerCase()?.replace(' ', '-')?.replace('.', '-');

const importExportData = (
  data: any[],
  port: string,
  year: number,
  month?: number,
  week?: number,
) => {
  const monthData = data?.filter(
    (x) =>
      Number(x.year) == year &&
      (Number(x.month) == month || Number(x.week) == week),
  );

  const exportData = monthData
    ?.filter((x) => slugifyPort(x.fromPort) == slugifyPort(port))
    ?.reduce((acc, cur) => acc + cur.totalSalesEur, 0);

  const importData = monthData
    ?.filter((x) => slugifyPort(x.toPort) == slugifyPort(port))
    ?.reduce((acc, cur) => acc + cur.totalSalesEur, 0);

  return {
    import: importData,
    export: exportData,
    x: month || week,
    port,
  };
};

const importExportDataLast3Months = (
  data: any[],
  port: string,
  year: number,
  month?: number,
) => {
  return getLastMonths(year, month, 3)
    .map((x) =>
      importExportData(filterPortData(data, port), port, x.year, x.month, null),
    )
    .reduce<Record<any, any>>(
      (acc, cur) => ({
        import: acc.import + cur.import,
        export: acc.export + cur.export,
        port,
      }),
      {import: 0, export: 0, port},
    );
};

const filterPortData = (data: any[], port: string): any[] => {
  return data?.filter(
    (x) =>
      slugifyPort(x.toPort) == slugifyPort(port) ||
      slugifyPort(x.fromPort) == slugifyPort(port),
  );
};

interface CityMapPageProps extends PageWithFilterProps {}

const CityMapPage = ({year, month}: CityMapPageProps) => {
  const [data, setData] = useState<any[]>(null);
  const [port, setPort] = useState<string>(null);

  const {messages} = useIntl();

  useEffect(() => {
    getDataGroup2().then((x) => setData(x));
  }, []);

  if (!data) return <Loading />;

  const ports = Array.from(
    new Set(data?.flatMap((x) => [x.toPort as string, x.fromPort as string])),
  );
  const portsMonthlyData = ports.reduce((acc, _port) => {
    return {
      ...acc,
      [slugifyPort(_port)]: importExportDataLast3Months(
        data,
        _port,
        year,
        month,
      ),
    };
  }, {});

  const portData = filterPortData(data, port);

  const importExportMonthlyData = port
    ? getLastMonths(year, month, 12).map((x) =>
        importExportData(portData, port, x.year, x.month, null),
      )
    : getLastMonths(year, month, 12).map((x) => ({
        x: x.month,
        import: 0,
        export: 0,
      }));

  const importExportWeeklyData = port
    ? getLastWeeks(year, Math.round(month * 4.35), 12).map((x) =>
        importExportData(portData, port, x.year, null, x.week),
      )
    : getLastWeeks(year, Math.round(month * 4.35), 12).map((x) => ({
        x: x.week,
        import: 0,
        export: 0,
      }));

  return (
    <>
      <AppRowContainer>
        <Col xs={22}>
          <AppCard>
            <div style={{position: 'absolute', top: 0, right: 0, width: '32%'}}>
              <AppCard
                title={messages['charts.last12Months'] as string}
                extra={
                  <span style={{fontWeight: 500, fontSize: 16}}>
                    {port?.toUpperCase()}
                  </span>
                }
                bordered
                noPadding
              >
                <ImportExportChart
                  containerName='europe-monthly-import-export-chart'
                  data={importExportMonthlyData}
                  height={145}
                />
              </AppCard>
              <AppCard
                title={messages['charts.last12Weeks'] as string}
                bordered
                noPadding
              >
                <ImportExportChart
                  containerName='europe-weekly-import-export-chart'
                  data={importExportWeeklyData}
                  height={145}
                />
              </AppCard>
            </div>
            <div>
              <div className='map-legend-container'>
                <div className='map-legend'>
                  <div className='map-legend-box blue'></div>
                  <span>ithalat fazlası</span>
                </div>
                <div className='map-legend'>
                  <div className='map-legend-box red'></div>
                  <span>ihracat fazlası</span>
                </div>
              </div>
              <Map
                portsData={portsMonthlyData}
                selectedPort={port}
                onPortSelected={setPort}
              />
            </div>
          </AppCard>
        </Col>
      </AppRowContainer>
    </>
  );
};

export default CityMapPage;
