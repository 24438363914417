import React from 'react';
import {PortType} from '..';

const Koper: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='adriatic-subregion'
        d='M53.79,17.93a2.14,2.14,0,0,1,1.35-.17c.24.09.32.35.36.62A11.08,11.08,0,0,0,57,18.1c.37-.14,1.21.32,1.3,0s.38-.93.75-.93.14-1,.51-1,1.31-.88,1.31-.88-.37-.75-.51-.8-.61.47-1.45.47-1.45.65-1.82.65-1.54-.37-2.33-.42l-.85-.06A18.25,18.25,0,0,0,53.79,17.93Z'
      />
      <circle className='port-center' cx='55' cy='17.67' r='0.41' />
      <circle className='port-circle' cx='55' cy='17.67' r='0.68' />
      <circle className='port-circle' cx='55' cy='17.67' r='0.94' />
    </g>
  );
};

export default Koper;
