import React from 'react';
import {DatePicker} from 'antd';
import type {Moment} from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

const AppDatePickerBase =
  DatePicker.generatePicker<Moment>(momentGenerateConfig);

const AppDatePicker = (props) => {
  return (
    <AppDatePickerBase style={{width: '100%', marginLeft: 8}} {...props} />
  );
};

export default AppDatePicker;
