import styled from 'styled-components';

type StyledVesselStoryVoyageSectionProps = {
  widthRatio: number;
  highlightRatio: number;
};

export const StyledVesselStoryVoyageSection = styled.div<StyledVesselStoryVoyageSectionProps>`
  height: 100%;
  width: ${({widthRatio}) => widthRatio}%;
  border: 1px solid black;
  margin-left: -1px;
  text-align: center;

  .duration {
    margin-top: 5px;
  }

  .vessel-story-voyage-section {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 100%;
    width: 100%;
    background-color: white;
    font-size: 0.4rem;
    font-weight: bold;

    @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
      font-size: 0.7rem;
    }

    .highlighted {
      display: flex;
      width: 100%;
      height: ${({highlightRatio}) => highlightRatio}%;
      background-color: #0a1f5c;
      border-top: 1px solid black;
      justify-content: center;
      align-items: ${({highlightRatio}) =>
        highlightRatio < 15 ? 'start' : 'center'};
      font-size: 18px;

      span {
        color: ${({highlightRatio}) =>
          highlightRatio < 15 ? '#0a1f5c' : 'white'};
        margin-top: ${({highlightRatio}) =>
          highlightRatio < 15 ? '-30px' : '0px'};
      }
    }
  }
`;
