import React, {useEffect, useState} from 'react';
import {PageWithFilterProps} from '@erkport/components/PageWrapper';
import {getLastMonths} from '@erkport/helpers/DateHelper';
import {getDataGroup2} from '@erkport/services/erkport';
import moment from '@erkport/services/moment';
import Loading from '@erkport/components/AppLoadable/Loading';
import Last12MonthsChart from '../components/last12MonthsChart';
import AppRowContainer from '@erkport/components/AppRowContainer';
import {Col} from 'antd';
import AppCard from '@erkport/components/AppCard';
import {useIntl} from 'react-intl';
import StateCardWithGraph from '@erkport/components/AppDashboard/StateCardWithGraph';
import {MdBarChart, MdPieChart} from 'react-icons/md';
import {formatNumberMK} from '@erkport/helpers/NumberFormatter';
import {monthNames} from '@erkport/constants/AppConst';
import ParetoChart from '../components/pareto';

const generalData = (data: any[], year: number, month: number) => {
  const monthData = data?.filter(
    (x) => Number(x.year) == year && Number(x.month) == month,
  );
  const filteredData = monthData?.reduce(
    (acc, cur) => ({
      totalSalesEur: (acc.totalSalesEur || 0) + cur.totalSalesEur,
      totalProfitEur: (acc.totalProfitEur || 0) + cur.totalProfitEur,
      profitability: (acc.profitability || 0) + cur.profitability,
      year,
      month,
    }),
    {
      totalSalesEur: 0,
      totalProfitEur: 0,
      profitability: 0,
      year,
      month,
    },
  );

  return filteredData;
};

const filterData = (
  data: any[],
  year: number,
  month: number,
  source: string,
) => {
  const filteredData =
    data
      ?.filter((x) => Number(x.year) == year && Number(x.month) == month)
      ?.reduce(
        (acc, cur) => {
          acc[cur[source]] = {
            totalSalesEur:
              (acc[cur[source]]?.totalSalesEur || 0) + cur.totalSalesEur,
            totalProfitEur:
              (acc[cur[source]]?.totalProfitEur || 0) + cur.totalProfitEur,
            profitability:
              (acc[cur[source]]?.profitability || 0) + cur.profitability,
          };
          return acc;
        },
        {'': ''},
      ) || [];

  return Object.entries(filteredData).map(([key, value]) =>
    key
      ? {
          [source]: key,
          ...(value as object),
          year,
          month,
        }
      : {
          year,
          month,
          totalSalesEur: 0,
          totalProfitEur: 0,
          profitability: 0,
        },
  ) as any[];
};

interface ProfitabilitySummaryPageProps extends PageWithFilterProps {}

const ProfitabilitySummaryPage = ({
  year,
  month,
  compareYear,
}: ProfitabilitySummaryPageProps) => {
  const [data, setData] = useState<{
    data: any[];
    prevData: any[];
    currentData: any[];
    customerSalesData: any[];
    portSalesData: any[];
    vesselSalesData: any[];
  }>(null);

  const {messages} = useIntl();

  const currentLast12Months = getLastMonths(year, month, 12);

  const prevLast12Months = getLastMonths(compareYear, month, 12);

  useEffect(() => {
    if (year && month) {
      (async function () {
        const data = (await getDataGroup2()) as any[];
        const [
          currentData,
          prevData,
          customerSalesData,
          portSalesData,
          vesselSalesData,
        ] = await Promise.all([
          currentLast12Months.map((x) => generalData(data, x.year, x.month)),
          prevLast12Months.map((x) => generalData(data, x.year, x.month)),
          currentLast12Months.map((x) =>
            filterData(data, x.year, x.month, 'customer'),
          ),
          currentLast12Months.map((x) =>
            filterData(data, x.year, x.month, 'fromPort'),
          ),
          currentLast12Months.map((x) =>
            filterData(data, x.year, x.month, 'vessel'),
          ),
        ]);

        setData({
          data,
          currentData,
          prevData,
          customerSalesData,
          portSalesData,
          vesselSalesData,
        });
      })();
    }
  }, [year, month]);

  if (!data) return <Loading />;

  return (
    <>
      <AppRowContainer>
        <Col xs={24} md={20}>
          <AppCard
            title={messages['charts.last12MonthsSalesProfit'] as string}
            noPadding
          >
            <Last12MonthsChart
              data={{
                prev: data.prevData.map((x) => ({
                  ...x,
                  month: messages[monthNames[x.month - 1]],
                })),
                current: data.currentData.map((x) => ({
                  ...x,
                  month: messages[monthNames[x.month - 1]],
                })),
              }}
              legends={['Satış', 'Kar', 'Geçmiş Satış', 'Geçmiş Kar']}
              containerName='profitability-summary-last-12-months'
              height={275}
            />
          </AppCard>
        </Col>
        <Col xs={24} md={4}>
          <AppRowContainer>
            <Col xs={24}>
              <StateCardWithGraph
                color='#6cb4ee'
                title={messages['common.last12MonthsTotalSales'] as string}
                value={formatNumberMK(
                  data.currentData.reduce(
                    (acc, cur) => acc + cur.totalSalesEur,
                    0,
                  ),
                  1,
                )}
                graphData={data.currentData.map((x) => ({
                  x: x.month,
                  y: x.totalSalesEur,
                }))}
              />
            </Col>
          </AppRowContainer>
          <AppRowContainer>
            <Col xs={24}>
              <StateCardWithGraph
                color='#132257'
                title={messages['common.last12MonthsTotalProfit'] as string}
                value={formatNumberMK(
                  data.currentData.reduce(
                    (acc, cur) => acc + cur.totalProfitEur,
                    0,
                  ),
                  1,
                )}
                graphData={data.currentData.map((x) => ({
                  x: x.month,
                  y: x.totalProfitEur,
                }))}
              />
            </Col>
          </AppRowContainer>
        </Col>
      </AppRowContainer>
      <AppRowContainer>
        <Col xs={4}>
          <StateCardWithGraph
            color='darkorange'
            title={messages['common.customer'] as string}
            value={data.customerSalesData
              ?.flatMap((x) => x)
              ?.filter((x) => x.year == year && x.month == month && x.customer)
              ?.length?.toString()}
            graphData={data.customerSalesData?.map((x) => ({
              x: x[0]?.month,
              y: x.length - 1,
            }))}
          />
        </Col>
        <Col xs={20}>
          <ParetoChart
            containerName='profitability-customer-pareto'
            height={275}
            data={Object.entries(
              data.customerSalesData
                ?.flatMap((x) => x)
                ?.filter((x) => x.year == year && x.month == month)
                ?.reduce(
                  (acc, cur) => ({
                    ...acc,
                    [cur.customer]:
                      (acc[cur.customer] || 0) + (cur.totalSalesEur || 0),
                  }),
                  {},
                ) || {},
            )
              .filter(([x]) => x != 'undefined')
              .map(([x, value]) => ({x, value}))}
          />
        </Col>
      </AppRowContainer>
    </>
  );
};

export default ProfitabilitySummaryPage;
