import React from 'react';
import {PortType} from '..';

const Toulon: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='baleric-subregion'
        d='M40.66,24.27a1.46,1.46,0,0,1-1.71.18,4.52,4.52,0,0,0-1-.4L37.69,24l.2-.25c.57-.7.82-1,.47-1.62s-3-3.56-4.5-5.31l-.64-.73.19-.16c.9-.76,1.78-1.5,2.61-2.16.74,1.36,2.85,5.21,3.18,5.8a8.81,8.81,0,0,1,.65,2.75A5.29,5.29,0,0,0,40.66,24.27Z'
      />
      <circle className='port-center' cx='39.15' cy='24.21' r='0.41' />
      <circle className='port-circle' cx='39.15' cy='24.21' r='0.68' />
      <circle className='port-circle' cx='39.15' cy='24.21' r='0.94' />
    </g>
  );
};

export default Toulon;
