import {formatNumberM, roundNumber} from '@erkport/helpers/NumberFormatter';

export const drawChart = (container, data, lineTitle, columnTitle) => {
  document.getElementById(container).innerHTML = '';

  var total = data?.reduce((acc, {value}) => acc + value, 0);
  var lineData = data.map(({value}) => value).makeCumulative();

  var dataSet = data
    .sort((a, b) => b.value - a.value)
    .map(({x, value}, i) => [
      x,
      value,
      roundNumber((lineData[i] * 100) / total),
    ]);

  // map data for the first series, take x from the zero column and value from the first column of data set
  var firstSeriesData = dataSet.map((x) => [x[0], x[1]]);

  // map data for the second series, take x from the zero column and value from the second column of data set
  var secondSeriesData = dataSet.map((x) => [x[0], x[2]]);

  // create column chart
  var chart = window.anychart.column();

  chart.padding(10);

  chart
    .yAxis(0)
    .labels()
    .format(function () {
      return `${formatNumberM(this.value, 1)}`;
    });

  // turn on chart animation
  chart.animation(true);

  // create second series with mapped data
  var columnSeries = chart.column(firstSeriesData);
  columnSeries.name(columnTitle);

  var scale = window.anychart.scales.linear();
  scale.minimum(0).maximum(110).ticks([0, 20, 40, 60, 80, 100]);

  var extraYAxis = chart.yAxis(1);
  extraYAxis.orientation('right').scale(scale);
  extraYAxis.labels().padding(0, 0, 0, 5);

  const xAxisWidth =
    lineData.length > 26
      ? 40
      : lineData.length > 20
      ? 60
      : lineData.length > 10
      ? 80
      : 100;

  chart
    .xAxis()
    .labels()
    .width(xAxisWidth)
    .wordBreak('keep-all')
    .wordWrap('normal');

  var lineSeries = chart.line(secondSeriesData);
  lineSeries.yScale(scale).labels().enabled(true).format('%{%value}');
  lineSeries.markers(false);
  lineSeries.name(lineTitle);

  // turn the legend on
  chart.legend().enabled(true);

  chart.credits().enabled(false);

  // set container id for the chart
  chart.container(container);
  // initiate chart drawing

  chart.draw();
};
