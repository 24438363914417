import Signout from '@erkport/modules/auth/Signout';
import React from 'react';

const Signin = React.lazy(() => import('../../modules/auth/Signin'));
const Signup = React.lazy(() => import('../../modules/auth/Signup'));
const ForgotPassword = React.lazy(
  () => import('../../modules/auth/ForgotPassword'),
);
export const authRouteConfig = [
  {
    path: '/signin',
    element: <Signin />,
  },
  {
    path: '/signout',
    element: <Signout />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
];
