import {Button, Card, Form, Layout} from 'antd';
import styled from 'styled-components';
import AppScrollbar from '../../components/AppScrollbar';

export const StyledAuthWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
`;

export const StyledAuthCard = styled(Card)`
  max-width: 900px;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 0 none;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({theme}) => theme.breakpoints.sm}px) {
    padding: 20px;
    width: 40%;
  }

  @media screen and (min-width: ${({theme}) => theme.breakpoints.lg}px) {
    width: 30%;
  }

  & .ant-card-body {
    padding: 0;
    display: flex;
    flex: 1;
  }
`;

export const StyledAuthMainContent = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledAuthCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    cursor: pointer;
    height: 36px;
    margin-right: 10px;

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 10px;
    }
  }
`;

export const StyledAuth = styled(Layout)`
  flex: 1;
  display: flex;
  position: relative;
  min-height: 100vh;
  justify-content: center;
  align-items: center;

  & .ant-layout-content {
    padding: 20px;
    justify-content: center;
    display: flex;
  }

  & .main-auth-scrollbar {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  & .footer {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const StyledMainAuthScrollbar = styled(AppScrollbar)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
