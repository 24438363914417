import React from 'react';
import ChartContainer from '@erkport/components/AppChartContainer';
import {drawChart} from './chart';
import {useIntl} from 'react-intl';

type ImportExportChartType = {
  containerName: string;
  data: any[];
  style?: any;
  height?: any;
  title?: string;
};

const ImportExportChart: React.FC<ImportExportChartType> = ({
  containerName,
  data,
  style,
  height,
  title = '',
}) => {
  const {messages} = useIntl();
  return (
    <ChartContainer
      containerName={containerName}
      drawChart={(container, data) =>
        drawChart(
          container,
          data,
          messages['charts.export'],
          messages['charts.import'],
          title,
        )
      }
      data={data}
      style={style}
      height={height}
    />
  );
};

export default ImportExportChart;
