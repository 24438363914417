import React from 'react';
import {PortType} from '..';

const Zeebrugge: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='continental-subregion'
        d='M43.86,9.23a24.46,24.46,0,0,1-3,1.17,13.11,13.11,0,0,0-3.09,1.95l-.21.16a23.69,23.69,0,0,0-1.4-2.86,9.1,9.1,0,0,0-4.57-3.2A6,6,0,0,1,30,5.75a.78.78,0,0,0,.12-.22,1.3,1.3,0,0,0,.07-.65c-.12-1.25.53-1.84,1.28-1.81a1.87,1.87,0,0,0,.47,0c.1.13.25.06.28.27s.56,1.12.74.8.66.42,1.08.65,1.26.51,1.3.8.19.88.61.7.93-.85.93-.47.14,1.07.61,1,.61.79,1.07.7,1.92,0,2.2.37a1.63,1.63,0,0,0,1.35.65c.61,0,.84.43,1.12.38S43.9,9,43.86,9.23Z'
      />
      <circle className='port-center' cx='31.96' cy='3.29' r='0.41' />
      <circle className='port-circle' cx='31.96' cy='3.29' r='0.68' />
      <circle className='port-circle' cx='31.96' cy='3.29' r='0.94' />
    </g>
  );
};

export default Zeebrugge;
