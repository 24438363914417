import React, {useState} from 'react';
import AppSidebar from './components/AppSidebar';
import AppHeader from './components/AppHeader';
import AppContentView from '../../components/AppContentView';
import AppFooter from './components/AppFooter';
import {
  StyledAppDrawerLayout,
  StyledAppDrawerLayoutMain,
  StyledDrawerScrollbar,
} from './index.styled';
import {RouterConfigData} from '@erkport/types/Apps';
import AppInfoView from '@erkport/components/AppInfoView';

type Props = {
  routes: React.ReactElement | null;
  routesConfig: RouterConfigData[];
};

const BaseLayout: React.FC<Props> = ({routes, routesConfig}) => {
  const [isVisible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <StyledAppDrawerLayout className='appMainFooter'>
      <AppSidebar
        visible={isVisible}
        onClose={onClose}
        routesConfig={routesConfig}
      />
      <StyledAppDrawerLayoutMain className='app-DrawerLayout-main'>
        <AppHeader showDrawer={showDrawer} />
        <StyledDrawerScrollbar>
          <AppContentView routes={routes} />
          <AppFooter />
        </StyledDrawerScrollbar>
      </StyledAppDrawerLayoutMain>
    </StyledAppDrawerLayout>
  );
};

export default React.memo(BaseLayout);
