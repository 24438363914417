import React from 'react';
import {PortType} from '..';

const Gemlik: React.FC<PortType> = ({
  importExportStatus,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      className={`port ${importExportStatus} ${isSelected ? 'selected' : ''}`}
    >
      <path
        className='other-subregion'
        d='M89.15,31.41s0,0-.07.08a10.43,10.43,0,0,1-4.41.92c-.34-.1-.77-.68-1.08-1.15a7.34,7.34,0,0,0,2.32-.21,1.25,1.25,0,0,0,.7-.56C88.06,30.83,89.13,31.2,89.15,31.41Z'
      />
      <circle className='port-center' cx='85.86' cy='30.89' r='0.41' />
      <circle className='port-circle' cx='85.86' cy='30.89' r='0.68' />
      <circle className='port-circle' cx='85.86' cy='30.89' r='0.94' />
    </g>
  );
};

export default Gemlik;
