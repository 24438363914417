import React from 'react';

import {BrowserRouter} from 'react-router-dom';
import AppContextProvider from '@erkport/context/AppContextProvider';
import AppThemeProvider from '@erkport/context/AppThemeProvider';
import AppLocaleProvider from '@erkport/context/AppLocaleProvider';
import AuthRoutes from './@erkport/components/AuthRoutes';
import AppLayout from '@erkport/layout/AppLayout';
import '@erkport/mockapi';
import {GlobalStyles} from 'GlobalStyle';
import {useThemeContext} from '@erkport/context/AppContextProvider/ThemeContextProvider';
import './styles/index.css';
import AuthProvider from '@erkport/context/AuthProvider';
import {QueryClient, QueryClientProvider} from 'react-query';
import AppInfoView from '@erkport/components/AppInfoView';

const cumulativeFunc = (sum) => (value) => {
  sum = sum + Number(value || 0);
  return sum;
};
declare global {
  interface Array<T> {
    makeCumulative(): Array<T>;
  }
}
Array.prototype.makeCumulative = function () {
  return this.map(cumulativeFunc(0));
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});
const App = () => {
  const {theme} = useThemeContext();
  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <AppThemeProvider>
          <AppLocaleProvider>
            <BrowserRouter>
              <AuthProvider>
                <AuthRoutes>
                  <GlobalStyles theme={theme} />
                  <AppLayout />
                  <AppInfoView />
                </AuthRoutes>
              </AuthProvider>
            </BrowserRouter>
          </AppLocaleProvider>
        </AppThemeProvider>
      </AppContextProvider>
    </QueryClientProvider>
  );
};

export default App;
