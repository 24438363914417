import ChartContainer from '@erkport/components/AppChartContainer';
import React from 'react';
import {drawChart} from './chart';

type TrendLineProps = {
  container: string;
  data: any[];
  field: string;
};

const TrendLine: React.FC<TrendLineProps> = ({container, data, field}) => {
  return (
    <ChartContainer
      containerName={`customer-trend-line-${container}`}
      drawChart={(containerName, _data) =>
        drawChart(containerName, _data, field)
      }
      data={data}
      height={50}
    />
  );
};

export default TrendLine;
