import moment from 'moment';
import 'moment/locale/tr';

moment.updateLocale('tr', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

moment.locale('tr');

export default moment;
