import React from 'react';
import StatGraphs from './StateGraph';
import {
  StyledAnaStateCard,
  StyledAnaStateContent,
  StyledAnaStateGraphs,
  StyledAnaStateHeader,
  StyledAnaStateItem,
  StyledAnaStateRow,
} from './index.styled';

type RevenueCardsType = {
  title: string;
  value: string;
  icon?: JSX.Element;
  highlightValue?: boolean;
  graphData?: any[];
  stackedBar?: boolean;
  labels?: object;
};

const StateCardWithBarChart: React.FC<RevenueCardsType> = ({
  title,
  value,
  icon = null,
  highlightValue = false,
  graphData = [],
  stackedBar = false,
  labels = null,
}) => {
  return (
    <StyledAnaStateCard>
      <StyledAnaStateContent>
        <StyledAnaStateRow>
          {icon == null ? (
            <StyledAnaStateItem style={{textAlign: 'left'}}>
              <p>{title}</p>
            </StyledAnaStateItem>
          ) : (
            <div
              style={{
                padding: 8,
                borderRadius: '50%',
              }}
            >
              {icon}
            </div>
          )}
          <StyledAnaStateItem>
            <StyledAnaStateHeader className='text-truncate'>
              {highlightValue ? <h2>{value}</h2> : <h3>{value}</h3>}
            </StyledAnaStateHeader>
            {icon != null && <p>{title}</p>}
          </StyledAnaStateItem>
        </StyledAnaStateRow>
        <StyledAnaStateGraphs>
          <StatGraphs
            data={graphData}
            stackedBar={stackedBar}
            labels={labels}
          />
        </StyledAnaStateGraphs>
      </StyledAnaStateContent>
    </StyledAnaStateCard>
  );
};

export default StateCardWithBarChart;
