import {monthNames} from '@erkport/constants/AppConst';
import {formatNumberMK} from '@erkport/helpers/NumberFormatter';
import React from 'react';
import useIntl from 'react-intl/lib/src/components/useIntl';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from 'recharts';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';

type StatGraphsProps = {
  data: {x: string; y: number}[];
  stackedBar: boolean;
  labels?: object;
};

type CustomTooltipProps = TooltipProps<ValueType, NameType> & {
  stacked: boolean;
  labels?: object;
};

const CustomTooltip = ({
  active,
  payload,
  label,
  stacked,
  labels,
}: CustomTooltipProps) => {
  const {messages} = useIntl();

  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: 'rgba(0,0,0,0.8)',
          padding: 5,
          borderRadius: 4,
        }}
      >
        {stacked ? (
          <p style={{fontSize: 12, color: '#fff'}}>
            {`${payload[0]?.payload?.x?.split('-')?.[0]} - ${
              messages[
                monthNames[Number(payload[0]?.payload?.x?.split('-')?.[1]) - 1]
              ]
            }`}{' '}
            <br />
            {`${labels?.['y1'] || 'y1'} : ${formatNumberMK(
              payload[0]?.payload?.y1,
              2,
            )}`}
            <br />
            {`${labels?.['y2'] || 'y2'} : ${formatNumberMK(
              payload[0]?.payload?.y2,
              2,
            )}`}
          </p>
        ) : (
          <p style={{color: '#fff'}}>
            {`${payload[0]?.payload?.x?.split('-')?.[0]} - ${
              messages[
                monthNames[Number(payload[0]?.payload?.x?.split('-')?.[1]) - 1]
              ]
            } : ${formatNumberMK(payload[0]?.value, 2)}`}
          </p>
        )}
      </div>
    );
  }

  return null;
};

const StatGraphs: React.FC<StatGraphsProps> = ({data, stackedBar, labels}) => {
  return (
    <ResponsiveContainer width='100%' height={80}>
      <BarChart data={data} margin={{top: 10, right: 0, left: 0, bottom: 10}}>
        <Tooltip
          content={<CustomTooltip stacked={stackedBar} labels={labels} />}
        />
        {stackedBar && <Bar dataKey='y1' stackId='y' fill='#8bbef2' />}
        <Bar dataKey='y2' stackId='y' fill='#0a1f5c' />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StatGraphs;
